.display {
    width: 100%;
    max-width: 40rem;
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: none;
    border-radius: 1rem;
    box-shadow: 2px 2px 8px #888;
    padding: 1rem 0;
    position: relative;
    background-color: #f6f6f6;
}
.header {
    width: 100%;
    height: fit-content;
    border-bottom: 2px solid #ccc;
    background-color: #607aa4;
    margin-top: -1rem;
    border-radius: 1rem 1rem 0 0;
    color: #f6f6f6;
    padding: 1rem 1rem 0;
}

.display_name {
    width: fit-content;
    height: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: -1.75rem;
    left: 1rem;
    padding: 0 1rem;
    border: none;
    border-radius: 2rem;
    box-shadow: 1px 1px 5px #222;
    color: #f6f6f6;
    background: #161d2d;
    font-size: 1.25rem;
}

.edit_save_btn,
.edit_save_btn:is(:hover,:active,:focus) {
    width: fit-content;
    height: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: -1.75rem;
    right: 1rem;
    padding: 0 1rem;
    border: none;
    border-radius: 2rem;
    box-shadow: 1px 1px 5px #222;
    color: #f6f6f6;
    background: #161d2d;
    font-size: 1.25rem;
}

.thumbnail {
    height: 100%;;
    width: auto;
    margin: 0 .75rem;
    border-radius: .5rem;
}


.edit_input_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 0 1rem;
}

.edit_save_btn:disabled {
    opacity: .6;
}

.nickname {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.description_wrap {
    width: 100%;
    height: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 0 1rem;
    margin-bottom: .75rem;
}
.description {
    width: 100%;
    height: fit-content;
    line-height: 1.25rem;
}

.notes_wrap {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0 1rem;
}
.notes {
    width: 100%;
    height: fit-content;
    line-height: 1.25rem;
    margin-bottom: .75rem;
}

.filled {
    top: .1rem !important;
    left: 1.5rem !important;
    font-size: 11px !important;
    text-transform: uppercase;
    opacity: 1;
}

.input,
.input:is(:hover,:focus,:active) {
    height: 3rem;
    width: 100%;
    border-radius: 2rem !important;
    border: none;
    padding-top: .5rem;
    padding-left: 1.5rem;
    background-color: #f6f6f6;
    box-shadow: 2px 2px 8px #888;
    color: #161d2d;
    outline: none;
    -webkit-appearance: none;
}

.file_upload_wrap {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}
.max_message {
    margin-bottom: .25rem;
}
.file_upload {
    display: none;
}
.file_upload:disabled + .file_label {
    cursor: default;
    color: #888;
}

.file_label,
.file_label:is(:hover, :focus) {
    cursor: pointer;
    height: 2rem;
    width: 8rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    border-radius: 2rem;
    background-color: #e9eaef;
    box-shadow: 3px 5px 8px #c6c7cb,
                -5px -5px 8px #f6f6f6;
}

.file_display {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 1rem 0;
}

.removed_added_files {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 1rem;
}

.file_container {
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1px solid #161d2d;
    border-radius: .5rem;
    height: 7rem;
    width: 6rem;
    margin: 0 .75rem;
    box-shadow: 2px 2px 5px #bbb;
}
.file_container_inner {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    border: none;
    border-radius: .5rem;
}

.display_icon {
    font-size: 1.35rem;
    color: #161d2d;
    margin-top: .5rem;
}

.remove_menu {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: -.5rem;
    right: -.5rem;
    height: 1.5rem;
    width: 1.5rem;
    border: none;
    border-radius: 50%;
    background-color: #f6f6f6;
    cursor: pointer;
    box-shadow: 2px 2px 5px #888;
}

.asset_textarea {
    width: 100%;
    height: 100%;
    border: none;
    outline: none;
    padding: 1rem;
    padding-left: 1.5rem;
    border-radius: 1rem !important;
    background-color: #f6f6f6;
    box-shadow: 2px 2px 8px #888;
    color: #161d2d;
    -webkit-appearance: none;
}

.floating_label {
    position: absolute;
    pointer-events: none;
    left: 1.5rem;
    top: .55rem;
    font-size: 1.25rem;
    transition: 0.2s ease all;
    color: #000;
    opacity: 0.8;
}

textarea:focus + .floating_label {
    top: .1rem;
    left: 1.5rem;
    font-size: 11px;
    text-transform: uppercase;
    opacity: 1;
}

.inputGroup {
    width: 100%;
    height: fit-content;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    margin-top: 1rem;
}

.fade {
    position: absolute;
    top: 0;
    left: 0;
    width: 96%;
    height: 1.5rem;
    border-radius: 1rem 0 0 0;
    background: linear-gradient(to bottom, #f6f6f6 60%, transparent);
}