.wrapper {
    width: 100%;
    height: 3.5rem;
    position: fixed;
    bottom: 0;
    z-index: 10;
    display: none;
}
.container {
    width: 100%;
    height: 3.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    background-color: #f6f6f6;
    border-radius: .75rem .75rem 0 0;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px -2px 6px 2px;
    color: #161d2d;
    z-index: 11;
}

.navItem {
    width: 20%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    line-height: 1.75rem;
    border-right: 1px solid #ddd;
    position: relative;
    cursor: pointer;
}
.navItem:last-of-type {
    border-right: none;
}
.navItem span {
    pointer-events: none;
}

.icon {
    height: 1.75rem;
    font-size: 1.5rem;
    pointer-events: none;
}
.word {
    font-size: .8rem;
    line-height: 1rem;
}
.circle {
    position: absolute;
    width: 2rem;
    height: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 3rem;
    top: -.5rem;
    background-color: #f6f6f6;
    border-radius: 50%;
    color: #161d2d;
}

.underline {
    width: 20%;
    height: .25rem;
    background-color: #607aa4;
    position: absolute;
    z-index: 11;
    bottom: 0;
    transition: all .5s ease-in-out;
}

.menuPosition,
.btnsPosition {
    position: absolute;
    /* bottom: 3.5rem; */
    /* bottom: 3rem; */
    bottom: 2.75rem;
    left: 0;
    width: 100%;
}
.btnsPosition {
    bottom: 3rem;
}
.menu {
    width: 100%;
    height: fit-content;
    background-color: #f6f6f6;
    /* padding: .5rem 0 1.25rem 0; */
    padding: .25rem 0 1.9rem 0;
    border-radius: .75rem .75rem 0 0;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px -2px 6px 2px;
}

.menuTitle, .menuItem {
    width: 100%;
    height: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #161d2d;
    background-color: #f6f6f6;
    transition: background-color .2s ease, color .2s ease;
}
.menuItem {
    cursor: pointer;
}

.menuItem.active {
    color: #f6f6f6;
    background-color: #607aa4;
    transition: background-color .2s ease, color .2s ease;
}

.logo {
    width: 100%;
    height: 4rem;
    display: flex;
    justify-content: center;
    align-items: center;
}
.logo img {
    width: auto;
    height: 100%;
}


.btns {
    width: 100%;
    height: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f6f6f6;
    color: #161d2d;
    border-radius: .75rem .75rem 0 0 !important;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px -2px 6px 2px;
}
.btnItem {
    width: 25%;
    height: 4rem;
    cursor: pointer;
    border: none;
    border-right: 1px solid #ddd;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    line-height: 1.75rem;
    padding: .35rem 0 .25rem;
    color: #161d2d;
    background-color: #f6f6f6;
}
.btnItem:disabled {
    opacity: .9;
    cursor: not-allowed;
}
.btnItem:first-of-type {
    border-radius: .75rem 0 0 0;
}
.btnItem:last-of-type {
    border-right: none;
    border-radius: 0 .75rem 0 0;
}
.btnItem span {
    pointer-events: none;
}

@media screen and (max-width: 500px) {
    .wrapper {
        display: initial;
    }
}