.container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 8rem 1rem 2rem;
    width: 100%;
    max-width: 30rem;
    margin: 0 auto;
}

.container :global(#signup-email):invalid {
    border: 1px solid red;
}
.container :global(#signup-email[value=""]) {
    border: none;
}

.container input,
.container input:is(:hover,:focus,:active) {
  height: 3rem;
  width: 100%;
  border-radius: 2rem !important;
  border: none;
  padding-top: .5rem;
  padding-left: 1.5rem;
  -webkit-appearance: none;
  background-color: #f6f6f6;
  box-shadow: 2px 2px 8px #888;
  color: #161d2d;
  outline: none;
}

.inputGroup {
    width: 100%;
    height: fit-content;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;
}

.icon {
    width: 2.5rem;
    height: 3rem;
    font-size: 1.1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0.25rem;
}

.label {
    position: absolute;
    pointer-events: none;
    left: 1.5rem;
    top: .55rem;
    font-size: 1.25rem;
    transition: 0.2s ease all;
    color: #000;
    opacity: 0.8;
}
  
.container input:focus + .label,
.container input:-webkit-autofill + .label,
.container input:not([value='']) + .label {
    top: .1rem;
    left: 1.5rem;
    font-size: 11px;
    text-transform: uppercase;
    opacity: 1;
}

.btn,
.btn:is(:hover,:focus,:active) {
  height: 3rem;
  width: 7rem;
  border-radius: 2rem !important;
  background: #161d2d;
  box-shadow:  2px 2px 8px #888;
  border: none;
  padding: .5rem .75rem;
  margin-top: 1.5rem;
  color: #f6f6f6;
}
.btn:disabled {
  opacity: .8;
}

.existing {
    font-size: .9rem;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0 .25rem;
    margin-bottom: 1rem;
}
.existing span {
    cursor: pointer;
    font-weight: bold;
}
.existing span:hover {
    color: #777;
    text-decoration: underline;
} 

.message {
    position: absolute;
    pointer-events: none;
    top: .1rem;
    left: 3.8rem;
    font-size: 11px;
    text-transform: uppercase;
    opacity: 1;
    color: red;
}

.hidePassword,
.hidePassword:is(:hover,:focus,:active) {
    height: 3rem;
    width: 3rem;
    position: absolute;
    right: .25rem;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.25rem;
    cursor: pointer;
    border: none;
    background-color: transparent;
    color: #161d2d;
}
.hidePassword:disabled {
    opacity: .8 !important;
    cursor: not-allowed;
}