.optionsContainer {
    width: calc(100% + 1rem);
    margin-left: -.5rem;
    max-height: 15rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    overflow-y: auto;
    padding: .5rem;
}

.addOptContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    margin: 0 0 1rem;
}

.inputGroup {
    width: 100%;
    height: fit-content;
    display: flex;
    position: relative;
}

.input {
    height: 2.5rem;
    width: 100%;
    border-radius: 2.5rem !important;
    border: none;
    padding: .35rem 1.25rem 0;
    background-color: #f6f6f6;
    box-shadow: 2px 2px 8px #888;
    color: #161d2d;
    outline: none;
    appearance: none;
    margin-right: .25rem;
}

.label {
    position: absolute;
    pointer-events: none;
    left: 1.25rem;
    top: .40rem;
    font-size: 1.15rem;
    transition: 0.2s ease all;
    color: #000;
    opacity: 0.8;
}

.input:focus + .label,
.input:-webkit-autofill + .label,
.input:not([value='']) + .label {
    top: 0rem;
    left: 1.25rem;
    font-size: 10px;
    text-transform: uppercase;
    opacity: 1;
}

.removeOptionBtn,
.removeOptionBtn:is(:hover,:active,:focus),
.addOptionBtn,
.addOptionBtn:is(:hover,:active,:focus) {
    cursor: pointer;
    color: #000000;
    background-color: #f6f6f6;
    box-shadow: 2px 2px 8px #888;
    border: none;
    border-radius: 2.5rem;
    margin-left: .25rem;
    width: 2.75rem;
    height: 2.5rem;
    padding: .5rem;
}

.removeOptionBtn:disabled,
.removeOptionBtn:is(:disabled),
.addOptionBtn:disabled {
    opacity: .6;
    cursor: not-allowed;
}

@media screen and (max-width: 500px) {
    .optionsContainer {
        align-items: center;
    }
}