.card {
    width: 10rem;
    height: fit-content;
    min-height: 10rem;
    padding: .5rem 1rem 1rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    box-shadow: 2px 2px 7px #888;
    border: none;
    border-radius: 1rem;
    background-color: #f6f6f6;
}

.tip i {
    margin-left: .25rem;
}

.name {
    width: 10rem;
    padding: 0 1rem .25rem;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.qr {
    min-height: 6rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.btns {
    width: 8rem;
    height: 1.75rem;
    display: flex;
    border: none;
    border-radius: 1.75rem;
    overflow: hidden;
    position: relative;
    margin-top: .5rem;
}

.unlinkBtn,
.unlinkBtn:is(:hover,:focus,:active) {
    width: 8rem;
    height: 1.75rem;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    border: none;
    border-radius: 1.75rem;
    background-color: #161d2d;
    color: #f6f6f6;
    transition: left .2s ease-in-out;
}
.unlinkBtn.hide {
    left: -8rem;
    transition: left .2s ease-in-out;
}

.confirmBtn,
.confirmBtn:is(:hover,:focus,:active) {
    width: 8rem;
    height: 1.75rem;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 8rem;
    border: none;
    border-radius: 1.75rem;
    background-color: #888;
    color: #f6f6f6;
    border: 2px solid #161d2d;
    transition: left .2s ease-in-out;
}
.confirmBtn.show {
    left: 0;
    transition: left .2s ease-in-out;
}


.btn,
.btn:is(:hover,:focus,:active) {
    width: 8rem;
    height: 1.75rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    border-radius: 1.75rem;
    background-color: #161d2d;
    color: #f6f6f6;
}