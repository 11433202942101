.filterWrap {
    width: 100%;
    height: fit-content;
    padding: 2rem;
    display: flex;
    justify-content: space-around;
    align-items: center;
    background-color: #f6f6f6;
    box-shadow:  2px 2px 8px #888;
    border-radius: 1rem;
    position: relative;
}

.title {
    position: absolute;
    top: -1.5rem;
    left: 1.5rem;
    background-color: #161d2d;
    color: #f6f6f6;
    height: 2rem;
    width: fit-content;
    padding: .5rem 1rem;
    border-radius: 2rem;
    box-shadow: 2px 2px 5px #888;
    font-size: 1.2rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.inputGroup {
    width: 100%;
    height: fit-content;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    position: relative;
}
.inputGroup:first-of-type {
    margin-right: .5rem;
}
.inputGroup:nth-of-type(2) {
    margin: 0 .5rem;
}
.inputGroup:last-of-type {
    margin-left: .5rem;
}

.dropdown {
    width: 100%;
    height: 3rem;
    border: none;
    border-radius: 3rem !important;
    background-color: #f6f6f6;
    color: #000;
    box-shadow: 2px 2px 5px #888;
    padding: .35rem 1.5rem .1rem;
    outline: none;
    appearance: none;
    -webkit-appearance: none;
    background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
    background-repeat: no-repeat;
    background-position-x: 95%;
    background-position-y: 55%;
    overflow: hidden;
    text-overflow: ellipsis;
}
.dropdown[id='analytics-categories'] {
    padding: .9rem 1.5rem .1rem;
}

.label {
    position: absolute;
    pointer-events: none;
    left: 1.5rem;
    top: .55rem;
    font-size: 1.25rem;
    transition: 0.2s ease all;
    color: #000;
    opacity: 0.8;
}
.dropdown:focus + .label,
.dropdown:-webkit-autofill + .label,
.dropdown:not([value='']) + .label {
    top: .1rem !important;
    left: 1.5rem !important;
    font-size: 10px !important;
    text-transform: uppercase !important;
    opacity: 1 !important;
}

.list {
    position: absolute;
    top: 0;
    left: 0;
    width: 12rem;
    height: fit-content;
    border: 1px solid #bbb;
    background-color: #666;
    border-radius: .4rem;
    box-shadow: 3px 3px 8px #bbb;
    padding: .5rem;
    z-index: 2;
    color: #f6f6f6;
}
.listTitle {
    border-bottom: 1px solid #f6f6f6;
}

.btn,
.btn:is(:hover,:focus,:active) {
    min-width: 6rem;
    height: 3rem;
    border: none;
    border-radius: 3rem;
    background-color: #f6f6f6;
    color: #161d2d;
    -webkit-appearance: none;
    margin-left: 1rem;
    box-shadow: 2px 2px 5px #888;
}

.picker {
    width: 100%;
    height: 3rem;
    background-color: #f6f6f6;
    padding: .5rem 1.5rem;
    border: none;
    border-radius: 3rem;
    box-shadow: 2px 2px 5px #888;
    background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
    background-repeat: no-repeat;
    background-position-x: 95%;
    background-position-y: 55%;
    outline: none;
    overflow: hidden;
    text-overflow: ellipsis;
}

.mainChart {
    width: 100%;
    height: fit-content;
    padding: 1rem;
    margin-top: 2rem;
    box-shadow: 2px 2px 8px #888;
    border-radius: 1rem;
    background-color: #f6f6f6;
}

.chartWrap {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: fit-content;
    margin-top: 2rem;
}

.subChart1,
.subChart2 {
    width: calc(50% - 1rem);
    height: fit-content;
    padding: 1rem;
    box-shadow: 2px 2px 8px #888;
    border-radius: 1rem;
    background-color: #f6f6f6;
}
.subChart1 {
    margin-right: 1rem;
}
.subChart2 {
    margin-left: 1rem;
}

@media screen and (max-width: 986px) {
    .chartWrap {
        flex-direction: column;
    }
    .subChart1,
    .subChart2 {
        width: 100%;
    }
    .subChart1 {
        margin-bottom: 2rem;
        margin-right: 0;
    }
    .subChart2 {
        margin-left: 0;
    }
}
@media screen and (max-width: 768px) {
    .filterWrap {
        flex-direction: column;
        padding: 2rem 1rem 1.5rem;
    }
    .inputGroup {
        margin: 0 0 .75rem !important;
    }
    .btn,
    .btn:is(:hover,:focus) {
        margin-top: .25rem;
        height: 2.5rem;
        border-radius: 2.5rem;
    }
    .dropdown,
    .picker {
        height: 2.5rem;
    }
    .dropdown[id='analytics-categories'] {
        padding: .8rem 1.5rem .1rem;
    }
    .dropdown {
        padding: .6rem 1.5rem .1rem;
    }
    .mainChart,
    .subChart1,
    .subChart2 {
        padding: .5rem;
    }
}