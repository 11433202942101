.checkboxWrap {
    width: 100%;
    display: flex;
    flex-direction: row;
    color: #161d2d;
}

.separator {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: #888;
    margin: .75rem 0;
}
.separator::before,
.separator::after {
    width: 1rem !important;
    content: '';
    /* flex: 1; */
    border-bottom: 1px solid #aaa;
    padding-top: .25rem;
}
.separator:not(:empty)::before {
    margin-right: .25rem;
}
.separator:not(:empty)::after {
    margin-left: .25rem;
}

.linkBtn,
.linkBtn:is(:hover,:focus,:active) {
    width: calc(100% - 1rem);
    height: 6rem;
    border: none;
    border-radius: .75rem;
    background-color: #f6f6f6;
    color: #161d2d;
    box-shadow: 1px 1px 4px #aaa;
    /* margin: .75rem 0; */
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.25rem;
}

.linkBtn i {
    margin: 0 .5rem 0;
}

.inputGroup {
    width: 100%;
    height: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
}

.checkbox {
    display: none;
}

.label {
    width: 100%;
    max-width: 14rem;
    height: 8rem !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 6;
    font-size: 1rem;
    border-radius: 1rem !important;
    box-shadow: 2px 2px 5px #aaa;
    user-select: none;
    color: #161d2d;
    cursor: pointer;
    margin: 0 .5rem;
    padding: 0 .5rem;
    text-align: center;
    /* border: 1px solid #161d2d; */
}
.checkbox:is(:checked, :active) + label {
    box-shadow: 2px 2px 5px #aaa;
    width: 100%;
    height: 8rem !important;
    z-index: 6;
    text-shadow: none;
    border-radius: 1rem !important;
    margin: 0;
    color: #161d2d;
    border: 2px solid #161d2d;
    transition: border .1s ease;
}
.checkbox:disabled + .label {
    color: #bbb;
}

.label i {
    font-size: 2rem !important;
    margin-bottom: .5rem;
}