.addLogContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: fit-content;
    padding-top: 1rem;
}
.typesContainer {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 1.5rem 0;
}

.btnContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 1rem;
}

.btn,
.btn:is(:hover,:focus,:active) {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 8rem;
    height: 2rem;
    border: none;
    border-radius: 2rem;
    box-shadow: 2px 2px 6px #888;
}

.cancel {
    background-color: #f6f6f6;
    color: #161d2d;
}

.save {
    background-color: #161d2d;
    color: #f6f6f6;
}

.save:disabled {
    opacity: 0.5;
}

.error {
    color: red;
    font-size: 0.8rem;
    margin: 0;
    text-align: center;
}

@media (max-width: 768px) {
    .addLogContainer {
        justify-content: flex-start;
        align-items: flex-start;
    }
}