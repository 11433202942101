.wrapper {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
}

.container {
    width: 100%;
    max-width: 40rem;
    height: 100%;
    padding: 8rem .5rem;
}