.wrapper {
    width: 100%;
    height: 100vh;
}

.container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin: 0 auto;
    color: #161d2d;
    padding: 8rem 3rem 4rem;
    width: 100%;
    max-width: 75rem;
    height: fit-content;
    min-height: 100%;
}

.nope {
    width: 100%;
    max-width: 30rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}
.nope button,
.nope button:is(:hover,:focus,:active) {
    width: 10rem;
    height: 2rem;
    border: none;
    border-radius: 2rem;
    background-color: #161d2d;
    color: #f6f6f6;
    margin-top: 1rem;
}


@media screen and (max-width: 900px) {
    .container {
        padding: 8rem .5rem 4rem;
    }
}

@media screen and (max-width: 500px) {
    .container {
        padding: 3rem .5rem 5rem;
    }
}