.wrapper {
    width: 100%;
    height: 100vh;
}

.container {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 8rem 5rem;
}

.card {
    width: 100%;
    max-width: 30rem;
    height: fit-content;
    padding-top: 1.5rem;
    border-radius: 1rem;
    box-shadow:  2px 2px 8px #888;
    background-color: #f6f6f6;
    position: relative;
}

.title {
    font-size: 1.25rem;
    width: fit-content;
    height: 2.5rem;
    padding: .25rem 1rem;
    background-color: #f6f6f6;
    border-radius: 3rem;
    box-shadow: 2px 2px 8px #888;
    position: absolute;
    top: -1.5rem;
    left: 1.5rem;
}

.inputGroup {
    width: 100%;
    height: fit-content;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    flex-wrap: wrap;
}
.input {
    width: 100%;
    height: 3rem;
    background-color: #f6f6f6;
    border: none;
    border-radius: 3rem !important;
    color: #000;
    padding: .5rem 1.5rem .2rem;
    box-shadow: 2px 2px 8px #888;
    outline: none;
    -webkit-appearance: none;
}

.label {
    position: absolute;
    pointer-events: none;
    left: 1.5rem;
    top: .55rem;
    font-size: 1.25rem;
    transition: 0.2s ease all;
    color: #000;
    opacity: 0.8;
}

.input:focus + .label,
.input:-webkit-autofill + .label,
.input:not([value='']) + .label,
.filled {
    position: absolute;
    top: 0rem;
    left: 1.5rem;
    font-size: 11px;
    text-transform: uppercase;
    opacity: 1;
}

.emailContainer,
.passwordContainer,
.timeContainer,
.creditsContainer,
.sonarContainer {
    width: 100%;
    height: fit-content;
    background-color: #f6f6f6 !important;
    border: none;
    border-bottom: 1px solid rgba(0,0,0,.125) !important;
    outline: none !important;
}
.emailContainer :global(.accordion-button),
.passwordContainer :global(.accordion-button),
.timeContainer :global(.accordion-button),
.creditsContainer :global(.accordion-button),
.sonarContainer :global(.accordion-button) {
    padding-left: 2rem;
    padding-right: 1.5rem;
    background-color: #f6f6f6;
}
.emailContainer :global(.accordion-button):not(.collapsed),
.passwordContainer :global(.accordion-button):not(.collapsed),
.timeContainer :global(.accordion-button):not(.collapsed),
.creditsContainer :global(.accordion-button):not(.collapsed),
.sonarContainer  :global(.accordion-button):not(.collapsed) {
    color: #000;
}

.emailContainer :global(.accordion-button):not(.collapsed)::after,
.passwordContainer :global(.accordion-button):not(.collapsed)::after,
.timeContainer :global(.accordion-button):not(.collapsed)::after,
.creditsContainer :global(.accordion-button):not(.collapsed)::after,
.sonarContainer :global(.accordion-button):not(.collapsed)::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.container :global(.accordion-item):last-of-type,
.creditsContainer :global(.accordion-button.collapsed) {
    border-bottom-left-radius: 1rem !important;
    border-bottom-right-radius: 1rem !important;
}

.container :global(.accordion-button) {
    outline: none !important;
}
.container :global(.accordion-button:focus) {
    outline: none !important;
    box-shadow: 0 0 0 .25rem rgb(150 150 150 / 25%)!important
}

.btn,
.btn:is(:hover,:focus,:active) {
    font-size: .9rem;
    width: 5rem;
    height: 3rem;
    border: none;
    border-radius: 3rem;
    color: #f6f6f6;
    background-color: #161d2d;
    position: absolute;
    border-radius: 0 3rem 3rem 0;
    right: 0;
}
.btn:disabled {
    opacity: .8;
    cursor: not-allowed;
}

.upgradeBtn,
.upgradeBtn:is(:hover,:focus,:active) {
    width: 8rem;
    height: 2.5rem;
    border: none;
    border-radius: 2.5rem !important;
    background-color: #161d2d;
    color: #f6f6f6;
    box-shadow: 2px 2px 8px #888;
}
.passwordCheckEnd {
    width: 3rem;
    height: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 3rem;
    position: absolute;
    right: 0;
}

.hidePassword,
.hidePassword:is(:hover,:focus,:active) {
    height: 3rem;
    width: 3rem;
    position: absolute;
    right: 5rem;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.25rem;
    cursor: pointer;
    border: none;
    color: #161d2d;
    background-color: transparent;
}
.hidePassword:disabled {
    opacity: .8 !important;
    cursor: not-allowed;
}

.container :global(#settings-sonar),
.container :global(#settings-credits),
.container :global(#settings-timezone) {
    background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='28' viewBox='0 0 24 24' width='28' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
    background-repeat: no-repeat;
    background-position-x: 97%;
    background-position-y: 55%;
}

.successOrError {
    font-size: .9rem;
    margin: .5rem 0 0 .5rem;
}

.timezone {
    width: 100%;
    height: 3rem;
    background-color: #f6f6f6;
    border: none;
    border-radius: 3rem !important;
    color: #000;
    padding: .5rem 1.5rem .2rem;
    box-shadow: 2px 2px 8px #888;
    outline: none;
    -webkit-appearance: none;
}


@media screen and (max-width: 768px) {
    .container {
        padding: 8rem 1rem;
    }
}
@media screen and (max-width: 500px) {
    .container {
        padding: 4rem .75rem;
    }
}