.search {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    height: 2rem;
    width: 2rem;
    background-color: #f6f6f6;
    border-radius: 2.5rem;
    opacity: 1;
    z-index: 1;
    border: none;
    outline: 1px solid #bbb;
    top: .5rem;
    right: .5rem;
    cursor: pointer;
    transition: width .25s ease, visibility .15s ease .15s, opacity .15s ease .15s;
}
.search.stretch {
    width: 13rem;
    transition: width .25s ease;
}
.search.hide {
    visibility: hidden;
    opacity: 0;
    transition: visibility .15s ease, opacity .15s ease;
}

.inputGroup {
    width: 100%;
    height: fit-content;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    position: relative;
}

.nicknameBtn,
.nicknameBtn:is(:hover,:focus,:active),
.tagBtn,
.tagBtn:is(:hover,:focus,:active) {
    visibility: hidden;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    width: 6.5rem;
    height: 2rem;
    border: none;
    color: #000;
    background-color: #f6f6f6;
    transition: background-color .2s ease, color .2s ease;
}
.nicknameBtn {
    border-radius: 2rem 0 0 2rem;
}
.tagBtn {
    border-radius: 0 2rem 2rem 0;
}
.tagBtn.stretch,
.nicknameBtn.stretch{
    visibility: visible;
    opacity: 1;
    transition: opacity .15s ease .25s, visibility .15s ease .25s;
}
.tagBtn.active,
.nicknameBtn.active {
    background-color: #161d2d;
    color: #f6f6f6;
    transition: background-color .2s ease, color .2s ease;
}

@media screen and (max-width: 768px) {
    .search {
        height: 1.75rem;
        width: 1.75rem;
        top: .375rem;
        right: .3755rem;
        font-size: .9rem;
    }
    .search.stretch {
        width: 11rem;
    }
    .nicknameBtn,
    .nicknameBtn:is(:hover,:focus,:active),
    .tagBtn,
    .tagBtn:is(:hover,:focus,:active) {
        height: 1.75rem;
        width: 5.5rem;
    }
}

@media screen and (max-width: 500px) {
    .search {
        top: .375rem;
        right: .375rem;
    }
    .search.stretch {
        width: 10rem;
    }
    .nicknameBtn.stretch {
        width: 6.5rem;
    }
}