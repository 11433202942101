.logContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
}
.logSection {
    width: 100% !important;
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}
.logSectionTitle {
    width: 100%;
    height: fit-content;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.table {
    width: 100%;
    height: fit-content;
    max-height: 20rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    border: none;
    border-radius: 0.5rem;
    overflow: scroll;
    box-shadow: 2px 2px 6px #888;
    color: #f6f6f6;
    outline: none;
    appearance: none;
}

.key {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding-top: 1rem;
    font-size: .85rem;
}

.logHeaders {
    width: 100%;
    min-width: 100%;
    border: none;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 100;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    height: 2.5rem;
    background-color: #161d2d;
}

.logHeaders .logType {
    border-right: 1px solid#f6f6f6;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 2.5rem;
    min-width: 6rem;
    padding: 0 .25rem;
    background-color: #161d2d;
}

.logRow {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    height: 2.5rem;
    color:#161d2d;
}

.deletedLog {
    color: red;
}

.logRow .logType {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 2.5rem;
    border-right: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    padding: 0 .25rem;
    min-width: 6rem;
}

.logRow:hover .logType {
    background-color: #ccc;
    cursor: pointer;
    border-right: 1px solid #fff;
}
.logRow .logType:last-of-type {
    border-right: none;
}

.logRow:last-child {
    border-bottom: none;
    border-radius: 0 0 0.5rem 0.5rem;
}

.logType i {
    transform: scale(-1, 1);
    transition: transform .2s ease;
}
.logType i.ascend {
    transform: scale(1, -1);
    transition: transform .2s ease;
}

.dateHeader {
    cursor: pointer;
}

.logType span {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.logType:last-child {
    border-right: none;
}

.none {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 2.5rem;
    overflow: clip;
    padding: .5rem .25rem;
    color: #000;
}

.selectedLogContainer {
    width: 100%;
    max-width: 35rem;
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 0;
    gap: 1.5rem 2rem;
}

.selectedLog {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    border: none;
    box-shadow: 2px 2px 6px #888;
    padding: 1rem;
    border-radius: .75rem;
}

.selectedLog p {
    margin-bottom: 0;
}

.selectedLog div {
    font-size: 1.25rem;
    line-height: 1.75rem;
    margin-bottom: .5rem;
}

.textareaDisplay {
    display: flex;
    flex-direction: column;
    font-size: 1rem;
}

.trackerCanvas {
    width: 350px !important;
    height: 100px !important;
    border: 1px solid #ccc;
    box-shadow: 2px 2px 8px #888;
    border-radius: .5rem;
    outline: none;
    appearance: none;
}

.btnContainer {
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: fit-content;
}

.deleted {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.deleted p {
    color: red;
    margin: 0;
}

.deletedToggle {
    color: blue;
    cursor: pointer;
    text-decoration: underline;
}

.btn,
.btn:is(:hover,:focus,:active),
.btnChangeLog,
.btnChangeLog:is(:hover,:active,:focus) {
    width: 6rem;
    height: 2.5rem;
    border: none;
    border-radius: 2.5rem;
    margin: 1rem 0.5rem;
    box-shadow: 2px 2px 8px #888;
    outline: none;
    appearance: none;
    background-color: #eee;
    color: #161d2d;
}

.btn:disabled {
    opacity: .5;
}

.btnContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
    height: fit-content;
}

.changeLog {
    text-decoration: underline;
    cursor: pointer;
    color: blue;
}

.changeLogWrapper {
    width: 100%;
    height: fit-content;
    
}

.changeLogContainer {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    border: none;
    box-shadow: 2px 2px 6px #888;
    padding: 1rem;
    border-radius: .75rem;
    margin-bottom: 1.5rem;
}

.changeLogEntry {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    height: fit-content;
    margin-bottom: 0.5rem;
}

.changeLogEntry:last-of-type {
    margin-bottom: 0;
}

.changeLogEntry p {
    margin-bottom: 0;
}

.changeLogDate {
    font-size: 1.15rem;
    font-weight: 600;
    margin-bottom: 0.75rem;
}

.changeLogColumn {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding-left: .75rem;
}

.changeLogValue {
    margin-left: 0.5rem;
}

.deleteBtnContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    position: relative;
}

.deleteBtnContainer span {
    position: absolute;
    top: 0;
    left: 0;
}

.deleteBtn,
.deleteBtn.btn:is(:hover,:focus,:active) {
    width: 6rem;
    height: 2.5rem;
    border: none;
    border-radius: 2.5rem;
    margin: 1rem 0.5rem;
    box-shadow: 2px 2px 8px #888;
    outline: none;
    appearance: none;
}

#deleteCancel {
    background-color: #eee;
    color: #161d2d;
}

#deleteConfirm {
    background-color:#161d2d;
    color: #eee;
}


@media screen and (max-width: 450px) {
    .trackerCanvas {
        transform: scale(.9);
        margin-left: -1.2rem;
    }
}
@media screen and (max-width: 400px) {
    .trackerCanvas {
        transform: scale(.85);
        margin-left: -1.7rem;
    }
}