.wrapper {
    width: 100%;
    height: 100vh;
}

.container {
    width: 100%;
    height: fit-content;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 8rem .5rem 4rem;
    text-align: center;
}

@media screen and (max-width: 768px) {
    .container {
        padding: 8rem .5rem 4rem;
    }
}
@media screen and (max-width: 500px) {
    .container {
        padding: 1rem .5rem 5rem;
    }
}