:global(.react-colorful) {
    width: 12rem !important;
}

.inputWrap {
    width: 12rem;
    position: relative;
}
.inputWrap input {
    width: 100%;
}

.card {
    width: 13rem;
    background: white;
    box-shadow: rgb(0 0 0 / 25%) 0px 0px 0px 1px, rgb(0 0 0 / 15%) 0px 8px 16px;
    border-radius: 0.25rem;
    position: 'relative',
}
.bottom {
    position: relative;
    padding: .5rem;
}
.saturationHue {
    margin: 0 auto .5rem;
    width: 300px;
    position: relative;
}
.label {
    float: left;
    font-size: 20px;
    margin-right: .5rem;
}