.display {
    width: fit-content;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
}
.display :global(.react-pdf__Page) {
    max-width: calc(100% - 2rem);
    box-shadow: 0 0 8px rgba(0,0,0,0.5);
    margin: 1rem;
}

.display :global(.react-pdf__Page__canvas) {
    width: 100% !important;
    height: auto !important;
}