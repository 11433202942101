
.containerInner {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    gap: 0 1.5rem;
}
.containerInner1 {
    width: 50%;
    min-width: 22rem;
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}
.containerInner2 {
    width: fit-content;
    padding: 1rem;
    background: #f6f6f6;
    box-shadow: 2px 2px 8px #888;
    border-radius: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.backBtn,
.backBtn:is(:hover,:focus,:active) {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    height: 2rem;
    width: 5rem;
    border-radius: 2rem;
    border: none;
    box-shadow: 2px 2px 5px #bbb;
    z-index: 10;
    top: -1.75rem;
    left: 1rem;
    color: #f6f6f6;
    background-color: #161d2d;
}
.createBtn,
.createBtn:is(:hover,:focus,:active) {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    height: 2rem;
    width: 5rem;
    border-radius: 2rem;
    border: none;
    box-shadow: 2px 2px 5px #bbb;
    z-index: 10;
    top: -1.75rem;
    right: 1rem;
    color: #f6f6f6;
    background-color: #161d2d;
}
.createBtn:disabled{
    color: #bbb;
    cursor: not-allowed;
}

.radioContainer {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 1.25rem 1rem;
    padding: 1rem 0;
}

.radio {
    display: none;
}
.label,
.label:is(:hover,:focus,:active) {
    width: 11rem;
    height: 2.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: .9rem;
    border: none;
    border-radius: .75rem;
    background-color: #f6f6f6;
    color: #161d2d;
    cursor: pointer;
    box-shadow: 2px 2px 6px #888;
    outline: none;
    appearance: none;
    position: relative;
    transition: background-color .2s ease;
}
.radio:checked + .label {
    background-color: #ddd;
    font-weight: 500;
    transition: background-color .2s ease;
}

.title {
    position: absolute;
    left: .75rem;
}
.color {
    position: absolute;
    right: .375rem;
    width: 2.5rem;
    height: 1.75rem;
    border: 2px solid #bbb;
    border-radius: .5rem;
}
.logo {
    position: absolute;
    right: .375rem;
    width: 2.5rem;
    height: 100%;
    font-size: 1.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
}
.square {
    position: absolute;
    right: .75rem;
    width: 1.75rem;
    height: 1.75rem;
    border: 2px solid #161d2d;
    border-radius: .25rem;
    background-color: transparent;
    transition: border-radius .3s ease;
}
.square.circle {
    border-radius: 50%;
    transition: border-radius .3s ease;
}

@media screen and (max-width: 768px) {
    .container {
        padding: 2.5rem 1rem 1rem;
    }
    .backBtn,
    .backBtn:is(:hover,:focus,:active) {
        left: 1rem;
        height: 2rem;
        width: 5rem;
        top: -1.75rem;
    }
    .nextBtn,
    .nextBtn:is(:hover,:focus,:active) {
        right: 1rem;
        height: 2rem;
        width: 5rem;
        top: -1.75rem;
    }
    .createBtn,
    .createBtn:is(:hover,:focus,:active) {
        height: 2rem;
        top: -1.75rem;
        right: 1rem;
    }
    .containerInner {
        flex-direction: column-reverse !important;
    }
    .containerInner1 {
        min-width: 0;
        width: 100%;
        padding: 1rem;
    }
    .containerInner2 {
        width: 100%;
        margin: 0;
        height: fit-content;
        min-height: 6rem;
    }
    .containerInner2 canvas {
        width: 75%;
    }
    .editStep {
        flex-direction: column-reverse !important;
    }
}
@media screen and (max-width: 500px) {
    .container {
        padding: 1.5rem .5rem;
    }
    .containerInner1 {
        padding: 1rem 0 1rem;
    }
    .qr-btn-back,
    .qr-btn-back:is(:hover,:focus,:active),
    .qr-btn-create,
    .qr-btn-create:is(:hover,:focus,:active) {
        top: -1.75rem;
    }
}