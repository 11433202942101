.backBtn,
.backBtn:is(:hover,:focus,:active) {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    height: 2rem;
    width: 5rem;
    border-radius: 2rem;
    border: none;
    box-shadow: 2px 2px 5px #bbb;
    z-index: 10;
    top: -1.75rem;
    left: 1rem;
    color: #f6f6f6;
    background-color: #161d2d;
}
.backBtn:disabled {
    opacity: .7;
    color: #bbb;
}

.title {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: .25rem;
}

.searchContainer {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin: .75rem 0 0;
}
.searchInner {
    width: 100%;
    height: fit-content;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.searchInner input {
    margin: 0 1rem 0 .25rem; 
    accent-color: #607ca7;
}
.searchInner label:first-of-type {
    margin-left: 1rem;
}

.inputGroup {
    width: 100%;
    height: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
}
.input,
.input:is(:hover,:focus,:active),
.select,
.select:is(:hover,:focus,:active) {
    width: 100%;
    height: 2rem;
    border: none;
    border-radius: 2rem;
    box-shadow: 2px 2px 8px #888;
    padding-left: 1rem;
    margin: .25rem 0 .5rem;
    outline: none;
}
.select,
.select:is(:hover,:focus,:active) {
    margin: .5rem 0;
    appearance: none;
}
.selectGroup {
    width: 100%;
    height: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}
.selectGroup::after {
    position: absolute;
    content: '\203A';
    top: .4rem;
    right: .75rem;
    font-size: 1.5rem;
    transform: rotate(90deg);
    pointer-events: none;
}

.resultContainer {
    width: 100%;
    height: fit-content;
}
.resultMessage {
    width: 100%;
    height: fit-content;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-left: 1rem;
    gap: 0 1rem;
}

.qrContainer {
    width: 100%;
    height: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 1.5rem 2rem;
    margin: 1rem 0;
}

.modalLink :global(.modal-content) {
    margin-top: 8rem;
    border-radius: .5rem !important;
}
.modalLink :global(.modal-header) {
    padding: .5rem 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.5rem;
}
.modalLink :global(.modal-body) {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}
.modalMessage {
    width: 100%;
    height: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    text-align: center;
}
.modalQrInfo {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.modalQrInfo span {
    font-weight: bold;
}
.modalBtns {
    width: 100%;
    height: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0 1rem;
}
.modalBtn,
.modalBtn:is(:hover,:focus,:active) {
    width: 7rem;
    height: 2rem;
    border: none;
    border-radius: 2rem;
    background-color: #161d2d;
    color: #f6f6f6;
}
.modalBtn:first-of-type {
    background-color: #888;
}