.wrapper {
    width: 100%;
    height: 100vh;
    margin: 0 ;
    padding-top: 8rem;
}

.container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    max-width: 30rem;
    margin: 0 auto;
    height: fit-content;
    padding: 0 1rem;
    color: #161d2d;
}
.container h2 {
    font-size: 2rem;
}

.container input,
.container input:is(:hover,:focus,:active) {
    height: 3rem;
    width: 100%;
    border-radius: 3rem !important;
    border: none;
    padding-top: .5rem;
    padding-left: 1.5rem;
    background-color: #f6f6f6;
    box-shadow: 2px 2px 8px #888;
    color: #161d2d;
    outline: none;
    -webkit-appearance: none;
}

.inputGroup {
    width: 100%;
    height: fit-content;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;
}

.input {
    height: 3rem;
    width: 100%;
    border-radius: 3rem !important;
    border: none;
    padding: .5rem 0 0 1.5rem;
    background-color: #f6f6f6;
    box-shadow: 2px 2px 8px #888;
    color: #161d2d;
    outline: none;
    appearance: none;
    margin-bottom: 1.25rem;
}

.label {
    position: absolute;
    pointer-events: none;
    left: 1.5rem;
    top: .55rem;
    font-size: 1.25rem;
    transition: 0.2s ease all;
    color: #000;
    opacity: 0.8;
}

.input:focus + .label,
.input:-webkit-autofill + .label,
.input:not([value='']) + .label {
    top: .1rem;
    left: 1.5rem;
    font-size: 11px;
    text-transform: uppercase;
    opacity: 1;
}

.btn,
.btn:is(:hover,:focus,:active) {
    height: 3rem;
    width: 7rem;
    border-radius: 2rem !important;
    background: #161d2d;
    box-shadow:  2px 2px 8px #888;
    border: none;
    padding: .5rem .75rem;
    margin-top: 1.5rem;
    color: #f6f6f6;
}
.btn:disabled {
    opacity: .8;
    color: #777;
}