.container {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}
.radioContainer {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 1.25rem 1rem;
    padding: 1rem 0;
}

.radio {
    display: none;
}
.label,
.label:is(:hover,:focus,:active) {
    width: 11rem;
    height: 2.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: .9rem;
    border: none;
    border-radius: .75rem;
    background-color: #f6f6f6;
    color: #161d2d;
    cursor: pointer;
    box-shadow: 2px 2px 6px #888;
    outline: none;
    appearance: none;
    position: relative;
    transition: background-color .2s ease;
}
.radio:checked + .label {
    background-color: #ddd;
    font-weight: 500;
    transition: background-color .2s ease;
}

.title {
    position: absolute;
    left: .75rem;
}
.color {
    position: absolute;
    right: .375rem;
    width: 2.5rem;
    height: 1.75rem;
    border: 2px solid #bbb;
    border-radius: .5rem;
}
.logo {
    position: absolute;
    right: .375rem;
    width: 2.5rem;
    height: 100%;
    font-size: 1.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
}
.square {
    position: absolute;
    right: .75rem;
    width: 1.75rem;
    height: 1.75rem;
    border: 2px solid #161d2d;
    border-radius: .25rem;
    background-color: transparent;
    transition: border-radius .3s ease;
}
.square.circle {
    border-radius: 50%;
    transition: border-radius .3s ease;
}