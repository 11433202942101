.enlarged {
    visibility: hidden;
    opacity: 0;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #010101;
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: opacity .3s ease .3s, visibility .3s ease .3s;
}
.enlarged.active {
    visibility: visible;
    opacity: 1;
    transition: opacity .2s ease, visibility .2s ease;
}
.enlargedInner {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}
.enlargedClose {
    position: absolute;
    top: 0;
    right: 0;
    width: 5rem;
    height: 5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #f6f6f6;
    font-size: 2.5rem;
    cursor: pointer;
}

.imgWrap {
    width: fit-content;
    height: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
}
.enlargedImg {
    visibility: hidden;
    opacity: 0;
    max-width: 80vw;
    max-height: 90vh;
    transition: opacity .3s ease, visibility .3s ease;
}
.enlargedImg.active {
    visibility: visible;
    opacity: 1;
    transition: opacity .3s ease .2s, visibility .3s ease .2s;
}

@media screen and (max-width: 600px){
    .enlargedImg {
        max-width: 90vw;
        max-height: 90vh;
    }
    .enlargedClose {
        width: 4rem;
        height: 4rem;
        font-size: 1.75rem;
    }
}