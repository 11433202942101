.wrapper {
    width: 100%;
    height: 100%;
}
.container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 0 12rem;
}

.topRow {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: nowrap;
    margin-bottom: 2rem;
    background-color: #f6f6f6;
    box-shadow: 2px 2px 8px #aaa;
    padding: 0 5rem;
    height: 8rem;
    border-radius: 8rem;
    gap: 1rem;
}

.topRowInner {
    border: none;
    outline: none;
    border-radius: 1rem;
    width: 100%;
    height: 6rem;
    max-width: 15rem;
    padding: 1rem 1.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* margin: 1rem; */
    position: relative;
    overflow: hidden;
    color: #f6f6f6;
    background-color: #161d2d;
    transition: outline .1s ease;
    cursor: pointer;
}
.topRowInner:first-of-type {
    margin-left: 0;
}
.topRowInner:last-of-type {
    margin-right: 0;
}
.topRowInner:hover {
    outline: 6px solid #607aa4;
    transition: outline .1s ease;
}

.topRowInner.disabled {
    background-color: #5e6470; /* Lighter shade of blue */
    cursor: default;
    pointer-events: none; /* Prevents click events */
}

.topRowInner i {
    font-size: 2rem;
    margin-right: 1rem;
}

.topRowInner i.iconOnly {
    margin-right: 0rem;
}

.topSection {
    display: flex;
    flex-direction: row; 
    align-items: center;
    justify-content: center;
    width: 100%; 
}

.bottomSection {
    width: 100%; 
    text-align: center;
    margin-top: .5rem;
}

/* .innerWrap {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
} */

.main {
    width: 100%;
    height: fit-content;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    /* height: 25rem; */
}
.mainTop {
    width: 100%;
    display: flex;
}
.mainTopLeft {
    width: calc(75% - 2rem);
    border-radius: 1rem;
    padding: 1rem 1.5rem;
    box-shadow: 2px 2px 8px #aaa;
    background-color: #f6f6f6;
    margin-right: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}
.mainTopRight {
    width: 25%;
    min-width: 25%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    border-radius: 1rem;
    box-shadow: 2px 2px 8px #aaa;
    background-color: #f6f6f6;
    padding: 1rem;
}


.mainBottom {
    width: 100%;
    display: flex;
    margin-top: 2rem;
}
.mainBottomLeft {
    width: calc(75% - 2rem);
    height: 5rem;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: nowrap;
    border-radius: 5rem;
    padding: 0 1rem;
    box-shadow: 2px 2px 8px #aaa;
    background-color: #f6f6f6;
    margin-right: 2rem;
}
.mainBottomRight {
    width: 25%;
    min-width: 25%;
    display: flex;
    flex-direction: column;
}
.mainTwoBtn,
.mainTwoBtn:is(:focus,:active) {
    background-color: #161d2d;
    color: #f6f6f6;
    border: none;
    outline: none;
    border-radius: .5rem;
    height: 3rem;
    width: 10rem;
    margin: .75rem;
    transition: outline .1s ease;
}
.mainTwoBtn:hover {
    outline: 3px solid #607aa4;
    transition: outline .1s ease;
}

.topQRsContainer {
    width: 100%;
    height: 100%;
    overflow-y: auto;
    border: 1px solid #161d2d;
    border-radius: .5rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    position: relative;
}
.topQRsWrap {
    width: calc(100% - 1rem);
    height: 7.5rem;
    position: absolute;
    left: .5rem;
}
.topQRs {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    line-height: 1.2rem;
}
.topQRs canvas {
    height: 50px;
    width: 50px;
}


.upgrade,
.upgrade:is(:active){
    display: flex;
    color: black;
    font-weight: bold;
    text-decoration: none;
    transform: scale(1);
    flex-wrap: nowrap;
    margin-right: 0.3rem;
}
.upgrade:is(:hover,:focus) {
    color: black;
    transform: scale(1.05);
    text-decoration-line: underline;
}

.arrow {
    width: 100%;
    height: 1.5rem;
    font-size: 1.25rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    line-height: .2rem;
    padding-top: .5rem;
}
.arrow span {
    font-size: .6rem;
}

@media screen and (max-width: 1200px) {
    .container {
        padding: 0 6rem;
    }
}

@media screen and (max-width: 992px) {
    .container {
        padding: 0 1.5rem;
    }
    .topRowInner {
        line-height: 1rem;
        height: 5rem;
    }
    .topRow {
        padding: 1rem 3rem;
    }
}

@media screen and (max-width: 768px) {
    .mainBottom {
        display: none;
    }
    .innerWrap h3 {
        font-size: 1.5rem;
    }
    .innerWrap p {
        font-size: .9rem;
    }
    .topRow {
        border-radius: 1rem;
        height: fit-content;
        flex-wrap: wrap;
        justify-content: space-evenly;
    }
    .topRowInner {
        height: 4rem;
        width: 10rem;
        padding: 0 1rem;
    }
    .topRowInner i {
        font-size: 1.5rem;
        margin-right: .75rem;
    }
    .topRowInner:first-of-type:hover,
    .topRowInner:last-of-type:hover {
        outline: 4px solid #607aa4;
        transition: outline .1s ease;
    }
    .main {
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
    }
    .mainTop {
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
    }
    .mainTopLeft {
        width: 100%;
        margin: 0;
    }
    .mainTopRight {
        width: 100%;
        margin-top: 2rem;
    }
    .topQRsContainer {
        max-height: 25rem;
    }
    .topQRsWrap {
        position: initial;
        height: fit-content;
    }
}

@media screen and (max-width: 500px) {
    .container {
        padding: 1rem .5rem;
    }
    .topRow {
        border-radius: 1rem;
        padding: .5rem;
        gap: .5rem;
    }
    .mainOne {
        padding: .5rem;
    }
}