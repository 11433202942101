.btn,
.btn:is(:hover,:focus,:active) {
    position: absolute;
    top: 0;
    left: 0;
    width: 2rem;
    height: 2rem;
    border: none;
    background-color: transparent;
}
.btn i {
    appearance: none;
    color: #f6f6f6;
    font-size: 1rem;
}
.childBtn,
.childBtn:is(:hover,:focus,:active) {
    position: absolute;
    top: 0;
    right: 0;
    width: 2rem;
    height: 2rem;
    border: none;
    background-color: transparent;
}
.childBtn i {
    appearance: none;
    color: #f6f6f6;
    font-size: 1rem;
}

.scanBtn,
.scanBtn:is(:hover,:active,:focus) {
    width: 6rem;
    height: 2rem;
    border: none;
    border-radius: 2rem;
    color: #f5f5f5;
    background-color: #161d2d;
    box-shadow: 2px 2px 5px #bbb;
}

.details {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}
.header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 3.5rem;
    width: 100%;
    text-align: center;
    color: #f6f6f6;
}
.headerInner {
    display: inline;
    line-height: 1rem;
    white-space: nowrap;
    max-width: 10rem;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 1.25rem;
    margin-bottom: .25rem;
}
.contentInner span {
    font-size: 1.25rem;
}
.header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 3.5rem;
    width: 100%;
    text-align: center;
}
.header span {
    width: 100%;
    max-width: 90%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 1.25rem;
}

.divider {
    height: 1rem;
    width: calc(100% + 1rem);
    border-right: none;
    border-top: 2px solid #ccc;
}

.detailsBody {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    /* overflow-y: auto; */
}
.tag {
    font-size: 1rem; 
    margin-left: .25rem;
}
.tag:first-of-type {
    margin-left: 0;
}
.tag:last-of-type span {
    display: none;
}
.scanInfo {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
    align-items: center;
    margin-top: .25rem;
}
.info span {
    font-size: 1.1rem; 
    font-weight: bold
}

.overlayTrigger {
    width: 8rem;
    height: 2rem;
    border-radius: 2rem;
    background-color: #ddd;
    color: #161d2d;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: .25rem;
    cursor: pointer;
}

.snapshotWrap {
    width: 100%;
    height: fit-content;
    display: flex;
    justify-content: center;
    flex-direction: row-reverse;
    align-items: center;
    flex-wrap: nowrap;
    gap: 0 .35rem;
}
.snapshotBtn,
.snapshotBtn:is(:hover,:focus,:active) {
    min-width: 8rem;
    min-height: 2rem;
    background-color: #ddd;
    color: #161d2d;
    border-radius: 2rem;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: .5rem 0 0;
    padding: 0 .5rem;
    text-decoration: none;
}
.snapshotDelete,
.snapshotDelete:is(:hover,:focus,:active) {
    width: 2rem;
    height: 2rem;
    border: none;
    border-radius: 50%;
    background-color: #ddd;
    color: #161d2d;
    margin: .5rem 0 0;
    padding: 0;
}
.snapshotDelete:disabled,
.snapshotDelete:disabled + .snapshotBtn {
    opacity: .7;
    cursor: not-allowed;
}

.snapshotUpload {
    visibility: hidden;
    position: absolute;
    top: -10px;
    height: 1px;
    width: 1px;
}
.snapshotUpload:disabled + .snapshotUploadBtn {
    opacity: .7;
    cursor: not-allowed;
}
.snapshotUploadBtn {
    width: 10rem;
    min-height: 2rem;
    background-color: #ddd;
    color: #161d2d;
    border-radius: 2rem;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: .5rem 0 0;
}

.snapDeleteWrap {
    width: fit-content;
    height: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
    margin-top: .5rem;
}
.snapDeleteCancel,
.snapDeleteCancel:is(:hover,:focus,:active),
.snapDeleteConfirm,
.snapDeleteConfirm:is(:hover,:focus,:active) {
    width: 3.5rem;
    height: 2rem;
    border: none;
    background-color: #ddd;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: .6rem;
    line-height: .7rem;
}
.snapDeleteCancel,
.snapDeleteCancel:is(:hover,:focus,:active) {
    color: red;
    border-radius: 2rem 0 0 2rem;
    border-right: .5px solid #aaa;
}
.snapDeleteConfirm,
.snapDeleteConfirm:is(:hover,:focus,:active) {
    border-left: .5px solid #aaa;
    color: green;
    border-radius: 0 2rem 2rem 0;
}
.snapDeleteCancel:disabled,
.snapDeleteConfirm:disabled {
    opacity: .7;
    cursor: not-allowed;
}