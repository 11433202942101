.container {
    width: 100%;
    height: 10rem;
    display: flex;
    justify-content: center;
    align-items: center;
}
.container i {
    font-size: clamp(1.5rem, 6vw, 5rem);
}
.spinnerUpgrade {
    width: 2rem;
    height: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
}
.spinnerUpgrade i {
    font-size: 1.25rem;
}

@media screen and (max-width: 480px) {
    .spinnerUpgrade i {
        font-size: 1rem;
    }
}