.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}

/* 
.fileUpload {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.fileLabel,
.fileLabel:is(:hover, :focus) {
    cursor: pointer;
    height: 2rem;
    width: 8rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    border-radius: 2rem;
    margin-bottom: .25rem;
    background-color: #e9eaef;
    box-shadow: 3px 5px 8px #c6c7cb,
                -5px -5px 8px #f6f6f6;
}

.fileInput {
    position: absolute;
    z-index: -1;
    opacity: 0;
    width: .1px;
    height: .1px;
    overflow: hidden;
} */

.file_display{
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    overflow-x: auto;
    padding: 1rem .2rem;
}

.file_container {
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1px solid #161d2d;
    border-radius: .5rem;
    height: 7rem;
    width: 6rem;
    margin: 0 .75rem;
    box-shadow: 2px 2px 5px #bbb;
}

.remove_menu {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: -.5rem;
    right: -.5rem;
    height: 1.5rem;
    width: 1.5rem;
    border: none;
    border-radius: 50%;
    background-color: #f6f6f6;
    cursor: pointer;
    box-shadow: 2px 2px 5px #888;
}

.thumbnail {
    height: 7rem;
    width: 6rem;
    margin: 0 .75rem;
    border-radius: .5rem;
}

.display_icon {
    font-size: 1.35rem;
    color: #161d2d;
    margin-top: .5rem;
}

.fileUpload {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.fileInput {
    position: absolute;
    z-index: -1;
    opacity: 0;
    width: .1px;
    height: .1px;
    overflow: hidden;
}

.fileLabel,
.fileLabel:is(:hover, :focus) {
    cursor: pointer;
    height: 2rem;
    width: 8rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    border-radius: 2rem;
    margin-bottom: .25rem;
    background-color: #e9eaef;
    box-shadow: 3px 5px 8px #c6c7cb,
                -5px -5px 8px #f6f6f6;
}

@media (max-width: 768px) {
    .file_display {
        justify-content: flex-start;
    }
}