.container {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    border-top: 1px solid #bbb;
    padding: 1.25rem 0;
    margin-top: 1rem;
}

.inputGroup,
.inputGroupSelect {
    width: 100%;
    height: fit-content;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    margin-bottom: .75rem;
}
.inputGroupSelect::after {
    position: absolute;
    content: '\203A';
    top: .25rem;
    left: 19.5rem;
    transform: rotate(90deg);
    pointer-events: none;
}
.label {
    margin-right: 1rem;
}

.select {
    height: 2rem;
    width: 11rem;
    border: none;
    border-radius: 2rem !important;
    padding-left: 1rem;
    background-color: #f6f6f6;
    box-shadow: 2px 2px 5px #888;
    appearance: none;
    outline: none;
    color: #000;
}

.rotation {
    height: 2rem;
    width: 5rem;
    border: none;
    border-radius: 2rem !important;
    padding-left: 1rem;
    background: #f6f6f6;
    box-shadow: 2px 2px 5px #888;
    outline: none;
    appearance: none;
}

.radio {
    display: none;
}
.radioBtn {
    width: fit-content;
    height: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f6f6f6;
    color: #161d2d;
    padding: 0 1rem;
    border: none;
    box-shadow: 2px 2px 5px #888;
    cursor: pointer;
    transition: background-color .2s ease,
                color .2s ease;
}
.radioBtn:nth-of-type(2) {
    border-radius: 2rem 0 0 2rem;
}
.radioBtn:nth-of-type(3) {
    border-radius: 0 2rem 2rem 0;
}
.radio:checked + .radioBtn {
    background-color: #161d2d;
    color: #f6f6f6;
    transition: background-color .2s ease,
                color .2s ease;
}

/* color picker style */
.swatch {
    height: 2rem;
    padding: 0 .5rem;
    width: 5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #fff;
    border: 1px solid #bbb;
    border-radius: 2rem;
    cursor: pointer;
}
.popover {
    position: absolute;
    bottom: 2.25rem;
    z-index: 4;
}
.cover {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}
.color {
    width: 100%;
    height: 1rem;
    border: 1px solid #000;
    border-radius: 1rem;
}
/* end color picker styling */


@media screen and (max-width: 500px) {
    .label {
        font-size: .9rem;
    }
    .select {
        width: 11rem;
        padding-right: 1.5rem;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .inputGroupSelect::after {
        left: 18.75rem;
    }    
}

@media screen and (max-width: 480px) {
    .swatch {
        width: 4rem;
    }
}