.container {
    display: flex;
    flex-direction: row;
    width: calc(100% + 4rem);
    margin-left: -2rem;
    margin-top: -2.5rem;
    margin-bottom: 1.5rem;
    border-radius: 1rem 1rem 0 0;
    overflow: hidden;
}

.tab {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: calc(100% / 3);
    padding: 1rem;
    margin: 0 auto;
    border-bottom: 1px solid #bcbcbc;
    border-right: 1px solid #bcbcbc;
    background-color: #eee;
    cursor: pointer;
    z-index: 0;
    user-select: none;
    transition: background-color .2s ease, box-shadow .2s ease;
}
.tab:last-of-type {
    border-right: none;
}

.tab.tabShadow {
    box-shadow: inset 0px -2px 5px #bbb;
    transition: box-shadow .2s ease;
}
.tab.tabActive {
    background-color: #f6f6f6;
    z-index: 9;
    border-bottom: none !important;
    transition: background-color .2s ease;
}

.disabled {
    pointer-events: none;
    opacity: 0.4;
}

@media screen and (max-width: 768px) {
    .container {
        width: calc(100% + 2rem);
        margin-left: -1rem;
    }
}

@media screen and (max-width: 500px) {
    .container {
        width: calc(100% + 1rem);
        margin-left: -.5rem;
        margin-top: -1.5rem;
    }
}