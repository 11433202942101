.wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 1rem;
}

.container {
    display: flex;
    background-color: white;
    border: 1px solid black;
    border-radius: 0.5rem;
    right: 1rem;
    width: fit-content;
    height: fit-content;
    padding: 0.5rem;
}

.text {
    width: 60%;
    text-align: center;
    margin-bottom: 1rem;
}

.errorContainer {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
    align-items: center;
}

.error {
    color: #D81B60;
}

.qrRef {
    
}