.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.inputGroup {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    position: relative;
}

.input,
.input:is(:hover,:focus,:active) {
    height: 3rem;
    width: 100%;
    border-radius: 2rem !important;
    border: none;
    padding-top: .5rem;
    padding-left: 1.5rem;
    background-color: #f6f6f6;
    box-shadow: 2px 2px 8px #888;
    color: #161d2d;
    outline: none;
    appearance: none;
    -webkit-appearance: none;
}

.label {
    position: absolute;
    pointer-events: none;
    left: 1.5rem;
    top: .55rem;
    font-size: 1.25rem;
    transition: 0.2s ease all;
    color: #000;
    opacity: 0.8;
}

.input:focus + .label,
.input:-webkit-autofill + .label,
.input:not([value='']) + .label,
.input:placeholder-shown + .label {
    top: .1rem;
    left: 1.5rem;
    font-size: 11px;
    text-transform: uppercase;
    opacity: 1;
}

.crosshairs {
    display: flex;
    position: absolute;
    border: none;
    border-radius: none;
    right: 1rem;
    top: 1rem;
    background-color: rgba(0, 0, 0, 0);
}