.wrapper {
    width: 100%;
    height: 100vh;
}

.container {
    width: 100%;
    height: fit-content;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin: 0 auto;
    padding: 8rem 3rem 4rem;
    max-width: 75rem;
}

.upgradeBtn {
    width: 8rem;
    height: 2.5rem;
    background-color: #161d2d;
    border: none;
    border-radius: 2.5rem;
    color: #f6f6f6;
    font-size: 1.25rem;
    font-weight: bold;
    margin-top: 1rem;
    cursor: pointer;
    box-shadow: 2px 2px 8px #888;
    outline: none;
}

@media screen and (max-width: 768px) {
    .container {
        padding: 8rem 1rem 4rem;
    }
}

@media screen and (max-width: 500px) {
    .container {
        padding: 3rem 1rem 5rem;
    }
}