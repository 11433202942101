.catContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    margin: .25rem 0;
    touch-action: none;
}

.input {
    width: 100%;
    height: 2rem;
    cursor: pointer;
    border: none;
    background-color: #f6f6f6;
    box-shadow: 2px 2px 6px #888;
    border-radius: 1rem;
    padding: 0 1rem;
    margin: .25rem;
    outline: none;
    transition: outline .1s ease;
}
.sectionHeader:active {
    outline: 3px solid #607aa4;
    box-shadow: 2px 2px 5px #bbb;
    transition: outline .1s ease, box-shadow .1s ease;
}

.iconContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 2rem;
    height: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: .25rem;
    color: #161d2d;
    border: none;
    font-size: 1.35rem;
    cursor: pointer;
}

.disabled {
    cursor: not-allowed;
    pointer-events: none;
}

.delUncategorized {
    opacity: .5;
}

.error {
    color: red;
    font-size: .8rem;
    margin: 0;
}