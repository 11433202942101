.wrapper {
    width: 100%;
    height: 100vh;
    padding: 8rem 5rem;
    display: flex;
    justify-content: center;
}
.container {
    width: 100%;
    max-width: 40rem;
    height: fit-content;
    border-radius: 1rem;
    box-shadow:  2px 2px 8px #888;
    padding: 1rem 0 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
}


.header {
    position: absolute;
    width: 8rem;
    height: 2.5rem;
    border-radius: 2.5rem;
    background-color: #f6f6f6;
    color: #161d2d;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.25rem;
    top: -1.5rem;
    left: 1.5rem;
    box-shadow: 2px 2px 8px #888;
}

.accordion {
    width: 100%;
    margin-top: 1rem;
}

.accordionItem {
    border: none;
    border-bottom: 1px solid rgba(0,0,0,.125) !important;
    background-color: #f6f6f6;
}
.accordionItem:first-of-type {
    border-top: 0;
}

.accordionItem:last-of-type,
.accordionItem:last-of-type button[aria-expanded='false'] {
    border-bottom-left-radius: 1rem !important;
    border-bottom-right-radius: 1rem !important;
}

.accordionItem button {
    outline: none !important;
    background-color: #f6f6f6;
    border: none;
}
.accordionItem button:not(.collapsed) {
    color: #000;
}
.accordionItem button:not(.collapsed)::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}


@media screen and (max-width: 768px) {
    .wrapper {
        padding: 8rem 1rem;
    }
}