.wrapper {
    width: 100%;
    height: 100vh;
}

.container {
    width: 100%;
    max-width: 30rem;
    height: fit-content;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin: 0 auto;
    padding: 8rem 1rem 2rem;
}

.container h2 {
    font-size: 2rem;
    margin-bottom: 1.5rem;
}

.inputGroup {
    width: 100%;
    height: fit-content;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    position: relative;
}

.inputGroup input,
.inputGroup input:is(:hover,:focus,:active) {
    height: 3rem;
    width: 100%;
    border-radius: 2rem !important;
    border: none;
    padding-top: .5rem;
    padding-left: 1.5rem;
    background-color: #f6f6f6;
    box-shadow: 2px 2px 8px #888;
    color: #161d2d;
    outline: none;
    -webkit-appearance: none;
}

.inputGroup input:focus + .label,
.inputGroup input:-webkit-autofill + .label,
.inputGroup input:not([value='']) + .label {
    top: .1rem;
    left: 1.5rem;
    font-size: 11px;
    text-transform: uppercase;
    opacity: 1;
}

.label {
    position: absolute;
    pointer-events: none;
    left: 1.5rem;
    top: .55rem;
    font-size: 1.25rem;
    transition: 0.2s ease all;
    color: #000;
    opacity: 0.8;
}

.btn,
.btn:is(:hover,:focus,:active) {
    height: 3rem;
    width: 7rem;
    border-radius: 2rem !important;
    background: #161d2d;
    box-shadow:  2px 2px 8px #888;
    border: none;
    padding: .5rem .75rem;
    margin-top: 1.5rem;
    color: #f6f6f6;
}
.btn:disabled {
    opacity: .8;
}
.btn:disabled{
    color: #777;
}