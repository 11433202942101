.container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 8rem 1rem 2rem;
    width: 100%;
    max-width: 30rem;
    margin: 0 auto;
}

.container :global(#ent-reg-email):invalid {
    border: 1px solid red;
}

.container :global(#ent-reg-email[value=""]) {
    border: none;
}

.inputsCont {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 1rem;
    gap: 1rem;
}

.container .input,
.container .input:is(:hover, :focus, :active) {
    height: 3rem;
    width: 100%;
    border-radius: 2rem !important;
    border: none;
    padding-top: .5rem;
    padding-left: 1.5rem;
    -webkit-appearance: none;
    background-color: #f6f6f6;
    box-shadow: 2px 2px 8px #888;
    color: #161d2d;
    outline: none;
}

.inputWrap {
    width: 100%;
    height: fit-content;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;
}

.label {
    position: absolute;
    pointer-events: none;
    left: 1.5rem;
    top: .55rem;
    font-size: 1.25rem;
    transition: 0.2s ease all;
    color: #000;
    opacity: 0.8;
}

.container .input:focus+.label,
.container .input:-webkit-autofill+.label,
.container .input:not([value=''])+.label {
    top: .1rem;
    left: 1.5rem;
    font-size: 11px;
    text-transform: uppercase;
    opacity: 1;
}

.btn,
.btn:is(:hover, :focus, :active) {
    height: 3rem;
    width: 12rem;
    border-radius: 2rem !important;
    background: #161d2d;
    box-shadow: 2px 2px 8px #888;
    border: none;
    padding: .5rem .75rem;
    color: #f6f6f6;
}

.btn:disabled {
    opacity: .8;
}

.backBtn,
.backBtn:is(:hover,:active,:focus) {
    background: white;
    color: #161d2d;
    border: none;
    border-radius: 2rem;
    width: fit-content;
    height: 2rem;

}

.checkCont {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.checkboxlabel {
    font-size: 0.8rem;
    margin-top: 1.5rem;
}

.checkbox {
    accent-color: #161d2d;
}

.editLink {
    font-size: 0.9rem;
    display: flex;
    justify-self: center;
    align-content: center;
    margin: auto;
}