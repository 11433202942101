.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.inputGroup {
    width: 100%;
    height: fit-content;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;
}

.input {
    height: 3rem;
    width: 100%;
    border-radius: 3rem !important;
    border: none;
    padding: .5rem 0 0 1.5rem;
    background-color: #f6f6f6;
    box-shadow: 2px 2px 8px #888;
    color: #161d2d;
    outline: none;
    appearance: none;
    margin-bottom: 1.25rem;
}

.label {
    position: absolute;
    pointer-events: none;
    left: 1.5rem;
    top: .55rem;
    font-size: 1.25rem;
    transition: 0.2s ease all;
    color: #000;
    opacity: 0.8;
}

.input:focus + .label,
.input:-webkit-autofill + .label,
.input:not([value='']) + .label {
    top: .1rem;
    left: 1.5rem;
    font-size: 11px;
    text-transform: uppercase;
    opacity: 1;
}

.uploadWrap {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: .5rem 0 0;
    position: relative;
}

.fileLabel,
.fileLabel:is(:hover, :focus) {
    cursor: pointer;
    height: 2rem;
    width: 8rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    border-radius: 2rem;
    background-color: #e9eaef;
    margin-bottom: .25rem;
    box-shadow: 2px 2px 8px #888;
}

.fileInput {
    position: absolute;
    z-index: -1;
    opacity: 0;
    width: .1px;
    height: .1px;
    overflow: hidden;
}

.btnContainer {
    width: 100%;
    height: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;
}

.resetBtn,
.resetBtn:is(:hover,:focus,:active),
.addBtn,
.addBtn:is(:hover,:focus,:active) {
    width: 6rem;
    height: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    border-radius: 2rem;
    background-color: #161d2d;
    color: #f6f6f6;
    box-shadow: 2px 2px 8px #888;
    margin: 0 .5rem;
}
.addBtn:disabled {
    opacity: .7;
    color: #eee;
}

.displayWrap {
    width: 100%;
    height: fit-content !important;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.displayContainer,
.displayContainerIconAdd {
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1px solid #161d2d;
    border-radius: .5rem;
    height: 7rem;
    width: 6rem;
    margin: 0 .75rem;
    box-shadow: 2px 2px 5px #bbb;
}
.displayContainerIconAdd {
    border: 1px dashed #161d2d;
    cursor: pointer;
}
.displayContainerIconAdd .nameDisplay {
    cursor: pointer;
}

.nameDisplay {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 6rem;
    padding: 0 .25rem;
    cursor: default;
}

.displayIcon {
    font-size: 1.35rem;
    color: #161d2d;
    margin-top: .5rem;
}

.removeBtn,
.removeBtn:is(:hover,:focus,:active) {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: -.5rem;
    right: -.5rem;
    height: 1.5rem;
    width: 1.5rem;
    border: none;
    border-radius: 50%;
    background-color: #f6f6f6;
    cursor: pointer;
    box-shadow: 2px 2px 5px #888;
}

.dupName {
    position: absolute;
    bottom: -1.25rem;
    left: 1.5rem;
    color: red;
}

.remaining {
    margin: .5rem 0;
}

@media screen and (max-width: 500px) {
    .dupName {
        bottom: -1.1rem;
        font-size: .8rem;
    }
}