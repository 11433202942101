.container {
    top: 0;
    width: 100%;
    height: 4rem;
    position: fixed;
    z-index: 200;
}

.inner {
    display: flex;
    justify-content: flex-end;
    text-align: center;
    width: 100%;
    height: 4rem;
    padding: 1rem;
    background-color: #f6f6f6;
    box-shadow: rgba(0, 0, 0, 0.19) 0px 5px 12px, rgba(0, 0, 0, 0.23) 0px 2px 6px;
    overflow: hidden;
    z-index: 10;
}

.background {
    position: absolute;
    height: 4rem;
    width: 100%;
    background-size: 12rem;
    background-position: 100% -110%;
    opacity: .15;
    left: 0;
    background-repeat: repeat-x;
}

.logo {
    position: absolute;
    width: 4.5rem;
    left: 3.75rem;
    top: .5rem;
    cursor: pointer;
}

.toggle {
    height: 2rem;
    width: 2rem;
    position: absolute;
    top: 1.1rem;
    left: 1.5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: baseline;
    transition: left .25s ease .25s;
    cursor: pointer;
    z-index: 21;
}
.toggle.open {
    left: 12rem;
    position: fixed;
    transition: left .25s ease;
    z-index: 21;
}

.bar1, .bar2, .bar3 {
    position: absolute;
    height: .15rem;
    width: 1.25rem;
    border-radius: 5px;
    background-color: #000;
    opacity: 1;
    transition: opacity .25s ease;
}
.bar1 {
    top: .4rem;
    transform: rotate(0deg);
    transition: top .25s ease .25s, transform .25s ease;
}
.bar2 {
    top: .75rem
}
.bar3 {
    top: 1.1rem;
    transform: rotate(0deg);
    transition: top .25s ease .25s, transform .25s ease;
}
.bar1.open {
    top: .75rem;
    transform: rotate(45deg);
    transition: top .25s ease, transform .25s ease .25s;
}
.bar2.open {
    opacity: 0;
    transition: opacity .25s ease;
}
.bar3.open {
    top: .75rem;
    transform: rotate(-45deg);
    transition: top .25s ease, transform .25s ease .25s;
}

.menuContainer {
    display: flex;
    flex-direction: column;
    position: fixed;
    height: 100vh;
    width: 11.25rem;
    top: 0;
    left: -12rem;
    border-right: 1px solid #888;
    box-shadow: none;
    z-index: 11;
    background-color: #f6f6f6;
    transition: left .25s ease .25s, box-shadow .25s ease .25s;
}
.menuContainer.open {
    left: 0rem;
    box-shadow: 1px 1px 10px #888;
    transition: left .25s ease;
}

.userContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 3;
    width: fit-content;
}

.icon {
    display: none;
    border: 3px solid #000;
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    cursor: pointer;
}
.icon .fas {
    pointer-events: none;
}

.usernameSm {
    display: none;
    position: absolute;
    top: 3.5rem;
    right: 1rem;
    background-color: #f6f6f6;
    padding: .1rem 1rem;
    height: 2rem;
    width: fit-content;
    border: 2px solid #000;
    border-radius: .5rem;
    box-shadow: 0 2px 5px #888;
}
.usernameSm.show {
    display: block;
}

.divider {
    width: 1rem;
    height: 2rem;
    margin-right: 1rem;
    border-right: 1px solid #bbb;
}

.iconContainer {
    width: 2rem;
    height: 2rem;
    font-size: 1.25rem;
    cursor: pointer;
    z-index: 2;
}
.iconContainer .fas {
    font-size: 1.4rem;
}

.iconContainer span {
    position: absolute;
    font-size: .4rem;
    color: #f6f6f6;
    top: .3rem;
    left: .25rem;
    font-weight: bold;
}

@media screen and (max-height: 500px) {
    .menuContainer.open {
        background-image: none !important;
    }
}
@media screen and (max-width: 768px) {
    .username {
        display: none;
    }
    .icon {
        display: block;
    }
}
@media screen and (max-width: 480px) {
    .menuContainer {
        width: 80%;
        z-index: 20;
        left: -80%;
    }
    .toggle.open {
        left: 17rem;
        z-index: 21;
    }
}

@media screen and (max-width: 500px) {
    .container {
        display: none;
    }
}