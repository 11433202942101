.wrapper {
    width: 100%;
    height: fit-content;
    min-height: 100vh;
    background-color: #A9A9A9;
}

.container {
    width: 100%;
    height: fit-content;
    min-height: 100%;
    padding: 6rem 2rem 5rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.body {
    width: 80%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: .75rem;
    padding: 1.25rem;
    background-color: #fff;
    box-shadow: 0 1px 1px rgb(0 0 0 / 11%),
                0 2px 2px rgb(0 0 0 / 11%),
                0 4px 4px rgb(0 0 0 / 11%),
                0 8px 8px rgb(0 0 0 / 11%),
                0 16px 16px rgb(0 0 0 / 11%),
                0 32px 32px rgb(0 0 0 / 11%);
}

.body :global(#signup-email):invalid {
    border: 1px solid red;
}
.body :global(#signup-email[value=""]) {
    border: none;
}

.body .input,
.body .input:is(:hover,:focus,:active) {
  height: 3rem;
  width: 100%;
  border-radius: 2rem !important;
  border: none;
  padding-top: .5rem;
  padding-left: 1.5rem;
  -webkit-appearance: none;
  background-color: #f6f6f6;
  box-shadow: 2px 2px 8px #888;
  color: #161d2d;
  outline: none;
}

.deleteContainer {
    width: fit-content;
    padding: 1rem 1.5rem;
    border: none;
    border-radius: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    position: relative;
}

.authBody {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0 1.5rem;
    padding: 1rem 0 0;
}

.inputGroup {
    width: 100%;
    height: fit-content;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;
}

.icon {
    width: 2.5rem;
    height: 3rem;
    font-size: 1.1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0.25rem;
}

.label {
    position: absolute;
    pointer-events: none;
    left: 1.5rem;
    top: .55rem;
    font-size: 1.25rem;
    transition: 0.2s ease all;
    color: #000;
    opacity: 0.8;
}
  
.body .input:focus + .label,
.body .input:-webkit-autofill + .label,
.body .input:not([value='']) + .label {
    top: .1rem;
    left: 1.5rem;
    font-size: 11px;
    text-transform: uppercase;
    opacity: 1;
}

.btn,
.btn:is(:hover,:active,:focus) {
    color: white;
    background-color: #364957;
    border: none;
    border-radius: 2.5rem;
    height: 2.5rem;
    width: 8rem;
}

.btn:disabled {
    opacity: 0.4;
}

.authErrorMsg {
    font-size: .9rem;
    color: #D81B60;
}

.btnsContainer {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    margin: 1rem 0;
}

.loginBtn,
.loginBtn:is(:active,:focus) {
    background: none;
    border: none;
    color: blue;
    cursor: pointer;
    padding: 0;
    font-size: inherit;
    text-decoration: underline;
}

.loginBtn:hover {
    color: #256aff;
}

.forgotPassword {
    font-size: .9rem;
    color: blue;
}

.forgotPassword:hover {
    color: #256aff;
}

@media screen and (max-width: 768px) { 
    .container {
        padding: 2rem 1rem 5rem;
    }
    .body {
        width: 100%;
    }
}