.container {
    width: 100%;
    height: 2rem;
}

.backgroundBar {
    height: .75rem;
    width: 100%;
    border-radius: .75rem;
    background-color: #ccc;
    position: relative;
}

.bar {
    height: .75rem;
    border-radius: .75rem;
    position: absolute;
    left: 0;
}

.word {
    font-size: .8rem;
    margin-bottom: 0;
    font-weight: bold;
}