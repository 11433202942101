.container {
    width: 100%;
    max-width: 57rem;
    height: fit-content;
    display: flex;
    flex-wrap: wrap;
    padding: 2.5rem 2rem;
    position: relative;
    margin: 0 auto;
}

.layout {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    height: 2.5rem;
    top: -1.25rem;
    right: 3rem;
    color: #161d2d;
}
.gridBtn,
.gridBtn:is(:hover,:focus,:active),
.listBtn,
.listBtn:is(:hover,:focus,:active) {
    width: 4rem;
    height: 2.5rem;
    border: none;
    background-color: #f6f6f6;
    overflow: hidden;
    font-size: 1.1rem;
    position: relative;
    box-shadow: 2px 2px 5px #bbb;
    transition: all .2s ease;
}
.gridBtn,
.gridBtn:is(:hover,:focus,:active) {
    border-radius: 2rem 0 0 2rem;
}
.listBtn,
.listBtn:is(:hover,:focus,:active) {
    border-radius: 0 2rem 2rem 0;
}
.gridBtn.active,
.listBtn.active {
    background-color: #161d2d;
    color: #f6f6f6;
    transition: all .5s ease;
}
.gridText,
.listText {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate( -50%, -50% );
    opacity: 1;
    transition: top .5s ease, opacity .5s ease;
}
.gridText.hide,
.listText.hide {
    top: 110%;
    opacity: 0;
    transition: top .5s ease, opacity .5s ease;
}
.gridIcon,
.listIcon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate( -50%, -50% );
    opacity: 1;
    transition: top .5s ease, opacity .5s ease;
}
.gridIcon.hide,
.listIcon.hide {
    top: -10%;
    opacity: 0;
    transition: top .5s ease, opacity .5s ease;
}

.inputGroup, .inputGroupTop {
    width: 100%;
    height: fit-content;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    position: relative;
}
.inputGroupTop {
    width: calc(100% - 4rem);
    margin-right: 1rem;
}

.paginate,
.paginate:is(:hover,:focus) {
    background: #161d2d;
    box-shadow:  2px 2px 8px #888;
    border: none;
    height: 2.5rem;
    width: 5rem;
    border-radius: 2rem;
    color: #f6f6f6;
    font-size: .9rem;
    margin: 1.5rem .5rem 0; 
}
.paginate:disabled {
    opacity: .8;
}

.input,
.input:is(:active,:hover) {
    width: 100%;
    height: 3rem;
    border-radius: 3rem !important;
    border: none;
    appearance: none;
    -webkit-appearance: none;
    padding-top: .5rem;
    padding-left: 1.5rem;
    background-color: #f6f6f6;
    box-shadow: 2px 2px 8px #888;
    color: #161d2d;
    outline: none;
}
.label {
    position: absolute;
    pointer-events: none;
    left: 1.5rem;
    top: .55rem;
    font-size: 1.25rem;
    color: #000;
    opacity: 0.8;
    transition: 0.2s ease all;
}
.input:focus + .label,
.input:-webkit-autofill + .label,
.input:not([value='']) + .label {
    top: .1rem;
    left: 1.5rem;
    font-size: 11px;
    text-transform: uppercase;
    opacity: 1;
}
.tagWrap {
    display: flex;
    justify-content: center;
    align-items: center;
    height: fit-content;
    width: 100%;
    margin: 0 0 1.75rem !important;
}

.noQRs {
    width: 100%;
}

.sonar,
.sonar:is(:hover,:focus){
    width: 10rem;
    height: 3rem;
    padding: 0;
    margin-right: 1rem;
    /* display: flex; */
    justify-content: center;
    align-items: center;
    border-radius: 3rem;
    color: #000;
    border: none;
    box-shadow: 2px 2px 6px #888;
    background-color: #f6f6f6;
}
.sonar:active{
    box-shadow: inset 5px 5px 8px #c6c7cb,
                inset -5px -5px 8px #f6f6f6;
}

.noReturnValue {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    margin-bottom: 1rem;
    font-size: 1.25rem;
}

/* starts shine css */
.shineWrap {
    position: absolute;
    height: 5rem;
    width: 5rem;
    border-radius: 5rem;
    top: -2.5rem;
    left: 1.75rem;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #607aa4;
}
.shineWrap i {
    font-size: 4rem;
}



@media screen and (max-width: 768px) {
    .container {
        justify-content: center;
    }
    .layout {
        display: none;
    }
    .input,
    .input:is(:hover,:focus,:active) {
        height: 2.5rem;
    }
    .input:is(:hover,:focus,:active) {
        padding-left: 1.25rem;
    }
    .label {
        top: .3rem;
        left: 1.25rem;
    }
    .input:focus + .label,
    .input:-webkit-autofill + .label,
    .input:not([value='']) + .label {
        top: 0rem;
        left: 1.25rem;
        font-size: 9px;
        text-transform: uppercase;
        opacity: 1;
    }
    .shineWrap {
        width: 3rem;
        height: 3rem;
        top: -1rem
    }
    .shineWrap i {
        font-size: 3.5rem;
    }
}

@media screen and (max-width: 500px) {
    .container {
        padding: 1.5rem 1rem
    }
    .inputGroupTop {
        margin-right: .5rem;
    }
    .noReturnValue {
        margin-bottom: .5rem !important;
    }
    .shineWrap {
        height: 2.75rem;
        width: 2.75rem;
        top: -1.7rem;
        left: 1.4rem;
    }
    .shineWrap i {
        font-size: 3.5rem;
    }
}