.wrapper {
    width: 100%;
    height: 100vh;
}

.container {
    width: 100%;
    height: fit-content;
    min-height: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding: 6rem .5rem;
}

.form {
    width: 100%;
    max-width: 45rem;
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #161d2d;
}

.btnContainer {
    width: 100%;
    height: fit-content;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;
}

.inputGroup {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    position: relative;
}

.radio {
    appearance: none;
}
.radioLabel {
    width: 10rem;
    height: 2rem;
    border: none;
    border-radius: 2rem;
    box-shadow: 2px 2px 5px #888;
    background: #f6f6f6;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 1rem .5rem;
    color: #161d2d;
}

.innerWrap {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.innerWrap label {
    font-size: 1rem !important;
    text-transform: none;
}

.inputs {
    width: calc(60% - 5rem);
    height: 2rem;
    border: none;
    border-radius: 2rem;
    outline: none;
    padding-left: 1rem;
    box-shadow: 0 2px 5px #888;
}

.btn,
.btn:is(:hover,:focus,:active) {
    width: 5rem;
    height: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #161d2d !important;
    color: #f6f6f6;
    border: none;
    border-radius: 2rem;
    margin-top: 1rem;
    box-shadow: 2px 2px 5px #888;
}
.btn:disabled {
    opacity: .8;
    color: #bbb;
}