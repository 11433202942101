.container {
    width: 3.5rem;
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #f6f6f6;
    border: none;
    border-radius: 4rem;
    box-shadow: 2px 2px 6px #aaa;
    padding: .625rem 0;
    gap: .625rem;
    position: fixed;
    right: .5rem;
    top: 50%;
    z-index: 999;
    transform: translateY(-50%);
}

.btn,
.btn:is(:focus,:active) {
    width: 2.25rem;
    height: 2.25rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    outline: none;
    border-radius: 50%;
    color: #161d2d;
    background-color: #f6f6f6;
    box-shadow: 1px 1px 5px #bbb;
    transition: outline .2s ease;
    appearance: none;
}
.btn:not(:disabled):hover {
    outline: 2px solid #161d2d !important;
    transition: outline .2s ease;
}
.btn:disabled {
    opacity: .8;
    cursor: not-allowed;
}

.sectionContainer {
    background-color: green;
}
.selectBtnWrap,
.reorderBtnWrap,
.collapsedBtnWrap {
    width: 2.25rem;
    height: 2.25rem;
    border: none;
    outline: none;
    border-radius: 50%;
    box-shadow: 1px 1px 5px #bbb;
    position: relative;
    overflow: hidden;
    transition: box-shadow .3s ease;
}
.closeBtn,
.closeBtn:is(:hover,:focus,:active),
.openBtn,
.openBtn:is(:hover,:focus,:active) {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #f6f6f6;
    color: #161d2d;
    border: none;
    border-radius: 50%;
    appearance: none;
    transition: z-index .2s linear;
}
.closeBtn:disabled,
.closeBtn:disabled:hover,
.closeBtn:disabled.show,
.openBtn:disabled,
.openBtn:disabled:hover,
.openBtn:disabled.hide {
    opacity: .5;
    cursor: not-allowed !important;
}
.closeBtn,
.closeBtn:is(:hover,:focus,:active) {
    pointer-events: none;
    opacity: 0;
    z-index: 3;
    transition: z-index .2s linear, opacity .2s linear;
}
.closeBtn.show {
    pointer-events: initial;
    opacity: 1;
    z-index: 4;
    transition: z-index .2s linear, opacity .2s linear;
}
.openBtn,
.openBtn:is(:hover,:focus,:active) {
    opacity: 1;
    pointer-events: initial;
    z-index: 4;
    transition: z-index .2s linear, opacity .2s linear;
}
.openBtn.hide {
    opacity: 0;
    pointer-events: none;
    z-index: 3;
    transition: z-index .2s linear, opacity .2s linear;
}
.selectBtnWrap:hover,
.reorderBtnWrap:hover,
.collapsedBtnWrap:hover {    
    outline: 2px solid #161d2d;
    transition: outline .2s ease;
}

.selectWrap {
    width: 11rem;
    position: absolute;
    right: 3.75rem;
}
.select {
    width: 10rem;
    height: fit-content;
    max-height: 60vh;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background-color: #f6f6f6;
    border: none;
    /* position: absolute;
    right: 4rem; */
    border-radius: .5rem;
    box-shadow: 2px 2px 5px #aaa;
    white-space: nowrap;
    margin: .5rem;
}

.selectHeader {
    width: 100%;
    background-color: #f6f6f6;
    border: none;
    position: sticky;
    top: 0;
    padding: .35rem;
}
.selectHeader span {
    background-color: #161d2d;
    height: 1.5rem;
    border: none;
    border-radius: 1.5rem;
    margin-bottom: .25rem;
    color: #f6f6f6;
    white-space: nowrap;
    text-wrap: nowrap;
    padding: .25rem .75rem;
}

.selectInner {
    width: 100%;
    height: fit-content;
    white-space: nowrap;
}

.selectOption {
    width: 100%;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;
    padding: .35rem .5rem;
    height: fit-content;
    line-height: 1rem;
}
.selectOption:hover {
    background-color: #ccc;
    transition: all .2s ease;
}

@media screen and (max-width: 768px) {
    .container {
        bottom: -1rem;
        top: initial;
        right: initial;
        width: fit-content;
        height: 3.5rem;
        flex-direction: row;
        padding: 0 .625rem;
        box-shadow: 2px 2px 8px #666;
        /* background-color: rgba(0,0,0,0.6); */
        background-color: rgba(22, 29, 45, 0.7);
    }
    .selectWrap {
        right: initial;
        bottom: 3.5rem;
    }
    .select {
        box-shadow: 2px 2px 5px #888;
    }
    .btn,
    .btn:is(:focus,:active) {
        box-shadow: 1px 1px 5px #000;
    }
    .selectBtnWrap,
    .reorderBtnWrap,
    .collapsedBtnWrap {
        box-shadow: 1px 1px 5px #000;
    }
}

@media screen and (max-width: 500px) {
    .container {
        visibility: hidden;
        position: fixed;
        z-index: 9;
        width: 100%;
        bottom: 0 !important;
        transform: translateY(0);
    }
    .selectWrap {
        visibility: visible;
        width: 100%;
        bottom: 6.5rem;
    }
    .select {
        width: 100%;
        height: fit-content;
        max-height: 12rem;
        overflow-y: auto;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        background-color: #f6f6f6;
        border-radius: .75rem .75rem 0 0;
        box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px -2px 6px 2px;
        white-space: nowrap;
        margin: 0;
        padding-bottom: 1rem;
    }
}