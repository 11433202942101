.sortBtn,
.sortBtn:is(:hover,:focus,:active) {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 3rem;
    width: 3rem;
    color: #161d2d;
    padding: 0;
    background-color: #f6f6f6;
    border-radius: 50%;
    z-index: 2;
    border: none;
    font-size: 1.1rem;
    box-shadow: 2px 2px 8px #888;
    position: relative;
    appearance: none;
    outline: none;
    cursor: pointer;
}

.sortMenuWrap {
    width: 15rem;
    position: absolute;
    top: 3.1rem;
    right: 0;
}
.sortMenu {
    width: 14rem;
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background-color: #f6f6f6;
    color: #161d2d;
    border: none;
    border-radius: .5rem;
    font-size: 1rem;
    box-shadow: 2px 2px 8px #888;
    z-index: 7;
    margin: .5rem;
    overflow: hidden;
}
.sortMenuOptionWrap {
    width: 100%;
    height: 8rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    transition: height .2s ease;
}
.filterMenuOptionWrap {
    width: 100%;
    height: 16rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    transition: height .2s ease;
}

.sortMenuOptionWrap.collapsed,
.filterMenuOptionWrap.collapsed {
    height: 0rem;
    transition: height .2s ease;
}
.sortMenuOption,
.filterMenuOption {
    width: 100%;
    height: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f6f6f6;
    color: #161d2d;
    transition: color .2s ease, background-color .2s ease;
}
.sortMenuOption:hover,
.filterMenuOption:hover {
    background-color: #161d2d;
    color: #f6f6f6;
    transition: color .2s ease, background-color .2s ease;
}
.sortMenuOption.active,
.filterMenuOption.active {
    background-color: #161d2d;
    color: #f6f6f6;
    transition: color .2s ease, background-color .2s ease;
}
.sortMenuSub,
.filterMenuSub {
    width: 100%;
    height: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ddd !important;
    color: #888 !important;
    z-index: 8;
}

.sortMenuSub i,
.filterMenuSub i {
    pointer-events: none;
    transform: rotate(180deg);
    transition: transform .2s ease;
}
.sortMenuSub.collapsed i,
.filterMenuSub.collapsed i {
    transform: rotate(0deg);
    transition: transform .2s ease;
}

@media screen and (max-width: 768px) {
    .sortBtn,
    .sortBtn:is(:hover,:focus,:active) {
        width: 2.5rem;
        height: 2.5rem;
        font-size: 1rem;
        z-index: 3;
    }
    .sortMenuWrap {
        top: 2.75rem;
    }
}