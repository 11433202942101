.container {
    position: absolute;
    display: flex;
    height: calc(100% / 7);
    cursor: pointer;
    justify-content: flex-start;
    align-items: flex-start;
    padding: .25rem;
    font-size: .8rem;
    opacity: .7;
    color: #f6f6f6;
    min-width: 0rem;
    transition: opacity .2s ease, min-width .2s ease;
}
.container:hover {
    opacity: 1;
    min-width: fit-content !important;
    z-index: 10;
    transition: opacity .2s ease, min-width .2s ease;
}

.words {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}
.words span {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-align: start;
}