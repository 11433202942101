.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.columnList {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* flex-wrap: wrap; */
    width: 100%;
}

.header {
    width: calc(100% + 4rem);
    margin: 0 0 1.5rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid #ddd;
}

@media screen and (max-width: 500px) {
    .header {
        width: calc(100% + 1rem);
        margin: 0 0 2rem -.5rem;
    }
}