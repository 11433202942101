.wrapper {
    width: 100%;
    height: 100vh;
}

.container {
    width: 100%;
    height: fit-content;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 8rem .5rem 4rem;
    text-align: center;
    overflow: hidden;
}

.inputGroup {
    width: 100%;
    height: fit-content;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.radio {
    display: none;
}
.radioLabel {
    width: 6rem;
    height: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f6f6f6;
    color: #161d2d;
    border: none;
    box-shadow: 2px 2px 5px #888;
    cursor: pointer;
    transition: background-color .2s ease,
                color .2s ease;
}
.radioLabel:first-of-type {
    border-radius: 2rem 0 0 2rem;
}
.radioLabel:last-of-type {
    border-radius: 0 2rem 2rem 0;
}

.radio:checked + .radioLabel {
    background-color: #161d2d;
    color: #f6f6f6;
    cursor: default;
    transition: background-color .2s ease,
                color .2s ease;
}

@media screen and (max-width: 900px) {
    .container {
        padding: 8rem .5rem 4rem;
    }
}

@media screen and (max-width: 500px) {
    .container {
        padding: 3rem .5rem 5rem;
    }
}