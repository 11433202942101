.container {
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100% !important;
    max-width: 50rem;
    background: #f6f6f6;
    box-shadow:  2px 2px 8px #aaa;
    padding: 2.5rem 2rem 1rem;
    border-radius: 1rem;
    margin-top: 2rem;
    margin-bottom: 1rem;
    position: relative;
}

.changeBtn,
.changeBtn:is(:hover,:focus){
    position: absolute;
    width: fit-content;
    height: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    border-radius: 2rem;
    padding: 0 1rem;
    z-index: 10;
    left: 6.5rem;
    top: -1.75rem;
    background: #161d2d;
    box-shadow: 2px 2px 5px #bbb;
    background-color: #161d2d;
    color: #f6f6f6;
    cursor: pointer;
}

.steps {
    width: 100% !important;
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.backBtn,
.backBtn:is(:hover,:focus,:active) {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    height: 2rem;
    width: 5rem;
    border-radius: 2rem;
    border: none;
    box-shadow: 2px 2px 5px #bbb;
    z-index: 10;
    top: -1.75rem;
    left: 1rem;
    color: #f6f6f6;
    background-color: #161d2d;
}
.backBtn:disabled {
    opacity: .7;
    color: #bbb;
}
.createBtn,
.createBtn:is(:hover,:focus,:active) {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    height: 2rem;
    width: 5rem;
    border-radius: 2rem;
    border: none;
    box-shadow: 2px 2px 6px #bbb;
    z-index: 9;
    top: -1.75rem;
    right: .75rem;
    background-color: #f6f6f6;
    color: #000;
}
.createBtn:disabled {
    background-color: #ddd;
    color: #888;
    cursor: not-allowed;
}

.nextBtn,
.nextBtn:is(:hover,:focus,:active) {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    height: 2rem;
    width: 5rem;
    border-radius: 2rem;
    border: none;
    box-shadow: 2px 2px 5px #bbb;
    z-index: 10;
    top: -1.75rem;
    right: 1rem;
    color: #f6f6f6;
    background-color: #161d2d;
}
.nextBtn:disabled{
    color: #bbb;
}

.containerInner {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}
.containerInner1 {
    width: 50%;
    min-width: 22rem;
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}
.containerInner2 {
    width: fit-content;
    min-width: 45%;
    padding: 1rem;
    background: #f6f6f6;
    box-shadow: 2px 2px 8px #888;
    margin-left: 1.5rem;
    margin-bottom: 1rem;
    border-radius: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.containerSteps {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

@media screen and (max-width: 768px) {
    .container {
        padding: 2.5rem 1rem 1rem;
    }
    .backBtn,
    .backBtn:is(:hover,:focus,:active) {
        left: 1rem;
        height: 2rem;
        width: 5rem;
        top: -1.75rem;
    }
    .nextBtn,
    .nextBtn:is(:hover,:focus,:active) {
        right: 1rem;
        height: 2rem;
        width: 5rem;
        top: -1.75rem;
    }
    .createBtn,
    .createBtn:is(:hover,:focus,:active) {
        height: 2rem;
        top: -1.75rem;
        right: 1rem;
    }
    .containerInner {
        flex-direction: column-reverse !important;
    }
    .containerInner1 {
        min-width: 0;
        width: 100%;
        padding: 1rem;
    }
    .containerInner2 {
        width: 100%;
        margin: 0;
        height: fit-content;
        min-height: 6rem;
    }
    .containerInner2 canvas {
        width: 75%;
    }
    .editStep {
        flex-direction: column-reverse !important;
    }
}
@media screen and (max-width: 500px) {
    .container {
        padding: 1.5rem .5rem;
    }
    .containerInner1 {
        padding: 1rem 0 1rem;
    }
    .qr-btn-back,
    .qr-btn-back:is(:hover,:focus,:active),
    .qr-btn-create,
    .qr-btn-create:is(:hover,:focus,:active) {
        top: -1.75rem;
    }
}