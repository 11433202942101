.inputGroup {
    width: 100%;
    height: fit-content;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    margin-bottom: 1.25rem;
}

.textArea {
    width: 100%;
    height: 100%;
    border: none;
    outline: none;
    padding: 1rem 1.5rem;
    border-radius: 1rem !important;
    background-color: #f6f6f6;
    box-shadow: 2px 2px 8px #888;
    color: #161d2d;
    -webkit-appearance: none;
}
.label {
    position: absolute;
    pointer-events: none;
    left: 1.5rem;
    top: .55rem;
    font-size: 1.25rem;
    transition: 0.2s ease all;
    color: #000;
    opacity: 0.8;
}

.textArea:focus + .label,
.textArea:-webkit-autofill + .label,
.labelFilled {
    position: absolute;
    top: .1rem;
    left: 1.5rem;
    font-size: 11px;
    text-transform: uppercase;
    opacity: 1;
}

.checkboxContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;
}
.checkbox {
    display: none;
}

.checkboxLabel {
    width: 15rem;
    height: 2.5rem;
    color: #000;
    user-select: none;
    font-size: 1.2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 2.5rem !important;
    background-color: #e9eaef;
    cursor: pointer
}

.fade {
    position: absolute;
    top: 0;
    left: 0;
    width: 96.5%;
    height: 1.5rem;
    border-radius: 1rem 0 0 0;
    background: linear-gradient(to bottom, #f6f6f6 60%, transparent);
}



@media screen and (max-width: 768px) {
    .checkboxContainer {
        flex-direction: column;
    }
    .checkboxLabel {
        margin-top: 1rem;
    }
    .header {
        width: calc(100% + 2rem);
        margin: 0 0 2rem -1rem;
    }
}