.container {
    width: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.wordsWrap {
    width: 100%;
    white-space: nowrap;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: .5rem 0;
}

.words {
    font-size: .8rem;
    font-weight: bold;
    margin: 0 .25rem 0 0;
}

.details {
    width: 100%;
    height: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1.25rem;
}

.btnWrap {
    width: 100%;
    height: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
}

.checkbox {
    display: none;
}
.checkbox + label {
    cursor: pointer;
    height: 3rem !important;
    width: 3rem;
    border: none;
    border-radius: 50%;
    background: #f6f6f6;
    box-shadow:  2px 2px 8px #888;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.5rem;
    user-select: none;
}
.checkbox:checked + label {
    box-shadow: inset 10px 10px 12px #c6c7cb,
                inset -10px -10px 12px #f6f6f6;
    color: #f6f6f6;
    text-shadow: 0 0 5px #f6f6f6, 
                0 0 10px #f6f6f6, 
                0 0 15px #f6f6f6, 
                0 0 20px #607aa4, 
                0 0 30px #607aa4, 
                0 0 40px #607aa4, 
                0 0 55px #607aa4, 
                0 0 75px #607aa4;
}

@media screen and (max-width: 600px) {
    .container {
        width: 6rem;
        padding-top: 0;
        padding-bottom: .5rem;
    }
}