.inputGroup {
    width: 100%;
    height: fit-content;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    margin-bottom: 1.25rem;
}

.textArea {
    width: 100%;
    height: 100%;
    border: none;
    outline: none;
    padding: 1rem 1.5rem;
    border-radius: 1rem !important;
    background-color: #f6f6f6;
    box-shadow: 2px 2px 8px #888;
    color: #161d2d;
    -webkit-appearance: none;
}
.label {
    position: absolute;
    pointer-events: none;
    left: 1.5rem;
    top: .55rem;
    font-size: 1.25rem;
    transition: 0.2s ease all;
    color: #000;
    opacity: 0.8;
}

.textArea:focus + .label,
.textArea:-webkit-autofill + .label,
.labelFilled {
    position: absolute;
    top: .1rem;
    left: 1.5rem;
    font-size: 11px;
    text-transform: uppercase;
    opacity: 1;
}
.fade {
    position: absolute;
    top: 0;
    left: 0;
    width: 96.5%;
    height: 1.5rem;
    border-radius: 1rem 0 0 0;
    background: linear-gradient(to bottom, #f6f6f6 60%, transparent);
}

.header {
    width: calc(100% + 4rem);
    margin: 0 0 2rem -2rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid #ddd;
}

@media screen and (max-width: 768px) {
    .header {
        width: calc(100% + 2rem);
        margin: 0 0 2rem -1rem;
    }
}

@media screen and (max-width: 500px) {
    .header {
        width: calc(100% + 1rem);
        margin: 0 0 2rem -.5rem;
    }
}