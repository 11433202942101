.form {
    width: 100%;
    max-width: 30rem;
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.inputGroup {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    position: relative;
}

.hidePassword,
.hidePassword:is(:hover,:focus,:active) {
    height: 3rem;
    width: 3rem;
    position: absolute;
    right: .25rem;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.25rem;
    cursor: pointer;
    border: none;
    color: #161d2d;
    background-color: transparent;
}
.hidePassword:disabled {
    opacity: .8 !important;
    cursor: not-allowed;
}

.forgotPassword {
    font-size: .9rem;
    margin-left: 1rem;
    color: #364957;
    transition: color .2s ease;
}
.forgotPassword:hover {
    color: #888;
    transition: color .2s ease;
}

.input,
.input:is(:hover,:focus,:active) {
    height: 3rem;
    width: 100%;
    border-radius: 2rem !important;
    border: none;
    padding-top: .5rem;
    padding-left: 1.5rem;
    background-color: #f6f6f6;
    box-shadow: 2px 2px 8px #888;
    color: #161d2d;
    outline: none;
    appearance: none;
    -webkit-appearance: none;
}

.label {
    position: absolute;
    pointer-events: none;
    left: 1.5rem;
    top: .55rem;
    font-size: 1.25rem;
    transition: 0.2s ease all;
    color: #000;
    opacity: 0.8;
}

.input:focus + .label,
.input:-webkit-autofill + .label,
.input:not([value='']) + .label {
    top: .1rem;
    left: 1.5rem;
    font-size: 11px;
    text-transform: uppercase;
    opacity: 1;
}


.btn,
.btn:is(:hover,:focus,:active) {
    height: 3rem;
    width: 7rem;
    border-radius: 3rem !important;
    background: #161d2d;
    box-shadow:  2px 2px 8px #888;
    border: none;
    padding: .5rem .75rem;
    margin: 1rem 0 .25rem;
    color: #f6f6f6;
}
.btn:disabled {
    color: #777;
    opacity: .8;
}

.needAccount {
    font-size: .9rem;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0 .25rem;
    margin-bottom: 1rem;
}
.needAccount span {
    cursor: pointer;
    font-weight: bold;
}
.needAccount span:hover {
    color: #777;
    text-decoration: underline;
} 