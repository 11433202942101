.container {
    width: 100%;
    height: fit-content;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    border-top: 1px solid #bbb;
    padding: 1.25rem 0;
    margin-top: 1rem;
}

.radio {
    display: none;
}
.radioBtn {
    width: fit-content;
    min-width: 5rem;
    height: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f6f6f6;
    color: #161d2d;
    padding: 0 1rem;
    border: none;
    box-shadow: 2px 2px 5px #888;
    cursor: pointer;
    transition: background-color .2s ease,
                color .2s ease;
}
.radioBtn:first-of-type {
    border-radius: 2rem 0 0 2rem;
}
.radioBtn:last-of-type {
    border-radius: 0 2rem 2rem 0;
}
.radio:checked + .radioBtn {
    background-color: #161d2d;
    color: #f6f6f6;
    transition: background-color .2s ease,
                color .2s ease;
}