.header {
    width: calc(100% + 4rem);
    margin: 0 0 2rem -2rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid #ddd;
}

@media screen and (max-width: 768px) {
    .header {
        width: calc(100% + 2rem);
        margin: 0 0 2rem -1rem;
    }
}

@media screen and (max-width: 500px) {
    .header {
        width: calc(100% + 1rem);
        margin: 0 0 2rem -.5rem;
    }
}