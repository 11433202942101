.container {
    width: 100%;
    height: fit-content;
}

.addWrap {
    width: 100%;
    height: 2rem;
    margin-bottom: 1.25rem;
    display: flex;
    justify-content: center;
    align-items: center;
    /* margin-top: -1rem; */
}
.addBtn,
.addBtn:is(:hover,:focus,:active) {
    width: 6rem;
    height: 2rem;
    border: none;
    border-radius: 2rem;
    background: #161d2d;
    color: #f6f6f6;
}

.scheduleWrap {
    width: 100%;
    height: fit-content;
    border: 1px solid #161d2d;
    border: 1px solid #161d2d;
    border-radius: .5rem;
    margin-bottom: 1rem;
    overflow-x: scroll;
    position: relative;
}
.scheduleWrap::-webkit-scrollbar {
    /* width: 0 !important; */
    height: 0 !important;
}

.schedule {
    width: 100%;
    min-width: 40rem;
    height: 36rem;
    position: relative;
}

.time {
    width: 100%;
    height: 3rem;
    background-color: #ddd;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: .8rem;
    padding-left: 2.5rem;
    position: sticky;
    top: 0;
}
.time span {
    width: calc(100% / 24);
}

.days {
    width: 2.5rem;
    height: calc(100% - 3rem);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background-color: #ddd;
    position: sticky;
    top: 0;
    left: 0;
    font-size: .8rem;
}
.days span {
    width: 100%;
    height: calc(100% / 7);
    display: flex;
    justify-content: center;
    align-items: center;
}

.layout {
    width: calc(100% - 2.5rem);
    height: calc(100% - 3rem);
    background-color: #f6f6f6;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    position: absolute;
    top: 3rem;
    left: 2.5rem;
}
.columns {
    position: absolute;
    width: calc(100% / 24);
    height: 100%;
    border-left: 1px solid #ccc;
}
.rows {
    position: absolute;
    width: 100%;
    height: calc(100% / 7);
    border-top: 1px solid #ccc;
}

.modalWrap {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 100;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,.5);
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding-top: 2rem;
}
.modal {
    width: 80%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background: #f6f6f6;
    border-radius: .5rem;
    padding: .5rem;
}
.modalHeader {
    width: 100%;
    height: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid #ddd;
}
.modalBody {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: .5rem;
}

.scheduler {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
}

.daysOfWeek {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: fit-content;
}

.checkboxWrap {
    width: 5rem;
    height: 2rem;
    margin: 0 .25rem .5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #161d2d;
    border-radius: .5rem;
    cursor: pointer;
    color: #f6f6f6;
    background-color: #161d2d;
}

.checkboxWrap label {
    cursor: pointer;
    user-select: none;
    width: fit-content;
    justify-self: flex-start;
    margin-left: .15rem;
}

.checkbox {
    margin-right: .15rem;
    accent-color: #f6f6f6;
    color: #161d2d;
    -webkit-appearance: checkbox;
    appearance: checkbox;
}

.timeWrap {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 1rem;
}
.timeWrapInner {
    width: 100%;
    display: flex;
    justify-content: space-between;
}
.timeContainer {
    width: calc(50% - 1rem);
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin-right: .75rem;
}
.timeInput {
    width: 100%;
    height: 2rem;
    border: none;
    border-radius: 2rem;
    outline: none;
    box-shadow: 2px 2px 5px #888;
    padding: 0 .5rem;
    background-color: #f6f6f6;
    appearance: none;
    font-size: 1rem;
    color: #161d2d;
}
.timeBtn,
.timeBtn:is(:hover,:focus,:active) {
    width: 10rem;
    height: 2rem;
    background-color: #f6f6f6;
    color: #161d2d;
    border: none;
    border-radius: 2rem;
    box-shadow: 2px 2px 5px #888;
    margin-top: 1rem;
}

.modalBottom {
    width: 100%;
    height: 2.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: .5rem;
}

.cancelBtn,
.cancelBtn:is(:hover,:focus,:active),
.approveBtn,
.approveBtn:is(:hover,:focus,:active) {
    width: 8rem;
    height: 2rem;
    border: none;
    border-radius: 2rem;
    background: #161d2d;
    color: #f6f6f6;
    margin: 0 .5rem;
}

.table {
    display: flex;
    position: relative;
    flex-direction: column;
    width: 100%;
    height: fit-content;
    max-height: 10rem;
    justify-content: flex-start;
    align-items: center;
    border: 1px solid #161d2d;
    border-radius: .25rem;
    overflow-y: scroll;
}
.tableHeader,
.tableRow {
    width: 100%;
    height: 1.5rem;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-bottom: 1px solid #ddd;
}
.tableRow {
    cursor: pointer;
}
tableRow:hover {
    background-color: #eee;
}
tableRow:last-of-type {
    border-bottom: none;
}
.tableHeader {
    position: sticky;
    top: 0;
    left: 0;
    background-color: #161d2d;
    color: #f6f6f6;
    font-size: .9rem;
}

.tableHeader div,
.tableRow div {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 1.5rem;
}
.tableRow div {
    font-size: .8rem;
}
.tableRow div:first-of-type {
    display: inline;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    line-height: 1.5rem;
    text-align: left;
}

.tableHeaderTitle:first-of-type {
    width: 35%;
    border-right: 1px solid #ddd;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding: 0 .5rem;
}
.tableHeaderTitle:nth-of-type(2),
.tableHeaderTitle:nth-of-type(3),
.tableHeaderTitle:nth-of-type(4),
.tableHeaderTitle:nth-of-type(5),
.tableHeaderTitle:nth-of-type(6),
.tableHeaderTitle:nth-of-type(7),
.tableHeaderTitle:nth-of-type(8) {
    width: 5%;
    border-right: 1px solid #ddd;
}
.tableHeaderTitle:nth-of-type(9) {
    width: 15%;
    border-right: 1px solid #ddd;
}
.tableHeaderTitle:nth-of-type(10) {
    width: 15%;
}

@media screen and (max-width: 768px) {
    .modal {
        width: 90%;
    }
}

@media screen and (max-width: 600px) {
    .modal {
        width: 98%;
    }
    .daysOfWeek {
        flex-wrap: wrap;
    }
}

@media screen and (max-width: 400px) {
    .tableHeaderTitle:first-of-type {
        width: 32%;
    }
    .tableHeaderTitle:nth-of-type(2),
    .tableHeaderTitle:nth-of-type(3),
    .tableHeaderTitle:nth-of-type(4),
    .tableHeaderTitle:nth-of-type(5),
    .tableHeaderTitle:nth-of-type(6),
    .tableHeaderTitle:nth-of-type(7),
    .tableHeaderTitle:nth-of-type(8) {
        width: 6%;
        border-right: 1px solid #ddd;
    }
    .tableHeaderTitle:nth-of-type(9) {
        width: 13%;
    }
    .tableHeaderTitle:nth-of-type(10) {
        width: 13%;
    }
}