.column {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin: 1rem 0 0;
    width: 100%;
}

.select {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 2.5rem;
    border-radius: 2.5rem !important;
    border: none;
    padding-left: 1.5rem;
    background-color: #f6f6f6;
    box-shadow: 2px 2px 8px #888;
    color: #161d2d;
    outline: none;
    appearance: none;
    margin-bottom: 1.25rem;
    font-size: 1.15rem;
}
.inputGroupSelect::after {
    position: absolute;
    content: '\203A';
    top: .2rem;
    right: 1rem;
    font-size: 1.5rem;
    transform: rotate(90deg);
    pointer-events: none;
}

.select:disabled {
    opacity: .5;
}

.saveCancelContainer {
    display: flex;
    justify-content: center;
    align-items: center;
}

.inputGroup,
.inputGroupSelect {
    width: 100%;
    max-width: 100%;
    height: fit-content;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;
}

.input {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    min-height: 3rem;
    border-radius: 1rem !important;
    border: none;
    padding: .75rem 1.25rem;
    background-color: #f6f6f6;
    margin-bottom: 1.25rem;
    outline: none;
    box-shadow: 2px 2px 8px #888;
}

.label {
    position: absolute;
    pointer-events: none;
    left: 1.25rem;
    top: .40rem;
    font-size: 1.15rem;
    transition: 0.2s ease all;
    color: #000;
    opacity: 0.8;
}

.input:focus + .label,
.input:-webkit-autofill + .label,
.labelFilled {
    position: absolute;
    top: 0rem;
    left: 1.25rem;
    font-size: 10px;
    text-transform: uppercase;
    opacity: 1;
}

.selectWrap {
    position: relative;
}
.addColumnBtn,
.addColumnBtn:is(:hover, :focus, :active) {
    width: 12rem;
    height: 2.5rem;
    border-radius: 2.5rem !important;
    border: none;
    background-color: #f6f6f6;
    box-shadow: 2px 2px 8px #888;
    color: #161d2d;
    outline: none;
    cursor: pointer;
    margin: .5rem .5rem;
    padding: 0 .75rem;
    appearance: none;
}
.selectWrap:after {
    position: absolute;
    content: '\203A';
    top: .7rem;
    right: 1rem;
    font-size: 1.25rem;
    transform: rotate(90deg);
    pointer-events: none;
}

.addColumnBtn:disabled {
    opacity: .6;
    cursor: not-allowed;
}

.saveCancelContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.optionWarn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0 .5rem;
    font-size: .9rem;
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0,0,0,.5);
    z-index: 10;
    padding: 0 .5rem;
}
.overlay.hidden {
    display: none;
}
.modal {
    width: 100%;
    max-width: 30rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    background-color: #fafafa;
    border: none;
    border-radius: 1rem;
    padding: 1rem;
    box-shadow: 1px 1px 10px #000;
    color: #161d2d;
}
.modalBody {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}
.modalHeader {
    padding: 0 .5rem .5rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    line-height: 1.5rem;
}
.modalHeader div {
    font-size: 1.5rem;
}
.modalHeader span {
    font-size: .8rem;
    color: #666;
}


@media screen and (max-width: 500px) {
    .column {
        flex-direction: column;
    }
    .inputGroup {
        margin-right: 0;
    }
    .inputGroupSelect {
        margin-left: 0;
    }
}