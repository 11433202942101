.wrapper {
    width: 100%;
    height: 100vh;
}

.container {
    width: 100%;
    height: fit-content;
    min-height: 100%;
    padding: 8rem 5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
}