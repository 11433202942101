@font-face {
    font-family: 'AkagiProW00-Fat';
    src: url("../../fonts/Akagi-Pro-Fat.woff2") format("woff2"), 
         url("../../fonts/Akagi-Pro-Fat.woff") format("woff");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

.menuHeader {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: fit-content;
    padding: 1rem;
    justify-content: center;
    align-items: center;
    font-family: 'AkagiProW00-Fat';
}

.btn,
.btn:is(:focus,:active) {
    background-color: transparent;
    width: 100%;
    height: 3rem;
    border: none;
    color: #000;
}
.btn:hover {
    background-color: rgba(0,0,0,.5);
    color: #f6f6f6;
}

.extra {
    height: 3rem;
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
    align-items: center;
    color: #888;
}
.extraLinks {
    background-color: transparent;
    border: none;
    color: #888;
}
.extraLinks:hover {
    text-decoration: underline;
}

@media screen and (max-width: 500px) {
    .menuHeader img {
        width: 70%;
    }
    .menuHeader h4 {
        font-size: 1.65rem;
    }
}