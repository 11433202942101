.btn,
.btn:is(:hover,:focus,:active),
.confirmBtn,
.confirmBtn:is(:hover,:focus,:active) {
    width: 7rem;
    min-width: fit-content;
    height: 2rem;
    border: none;
    padding: .25rem 1rem;
    background-color: #161d2d;
    color: #f6f6f6;
    box-shadow: 2px 2px 5px #888;
    border-radius: 2rem;
    z-index: 5;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.1rem;
    transition: border .2s ease;
    white-space: nowrap;
}

.btn:hover {
    border: 4px solid #607aa4;
    transition: border .2s ease;
}

.btn:disabled,
.confirmBtn:disabled{
    opacity: .8;
}

@media screen and (max-width: 600px) {
    .btn,
    .btn:is(:hover,:focus,:active),
    .confirmBtn,
    .confirmBtn:is(:hover,:focus,:active) {
        height: 2rem;
        width: 6rem;
        font-size: 1rem;
    }
    .btn:hover {
        border: 3px solid #607aa4;
        transition: border .2s ease;
    }
}