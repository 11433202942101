.container {
    width: fit-content;
    max-width: 50rem;
    padding: 1rem 1.5rem;
    background-color: #f6f6f6;
    border: none;
    border-radius: 1rem;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    position: relative;
}
.container h2 {
    width: calc(100% + 3rem);
    font-size: 1.5rem;
    color: #161d2d;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: 0;
    padding-bottom: .5rem;
    border-bottom: 1px solid #ccc;
}

.addQrContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
}

.qrContainer {
    width: 10rem;
    border-radius: 1rem;
    padding: 1rem 1.5rem;
    box-shadow: 2px 2px 8px #aaa;
    background-color: #f6f6f6;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.body {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0 1.5rem;
    padding: 1rem 0 0;
}

.addQrBtn,
.addQrBtn:is(:active,:hover,:focus),
.deleteQrBtn,
.deleteQrBtn:is(:active,:hover,:focus) {
    width: 10rem;
    height: 2.5rem;
    border: none;
    border-radius: 2.5rem;
    background-color: #161d2d;
    color: #ddd;
}

.addQrBtn:disabled {
    pointer-events: none;
    opacity: 0.7;
}

.deleteExistingContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.deleteText {
    margin-bottom: 0.75rem;
}

.cancelBtn,
.cancelBtn:is(:hover,:active,:focus) {
    width: 6rem;
    height: 1.75rem;
    border: none;
    border-radius: 1.75rem;
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    background-color: #161d2d;
    color: #ddd;
    text-decoration: none;
}


.confirmDeleteBtnContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0 .75rem;
    margin-top: 1rem;
}
.confirmDeleteBtn,
.confirmDeleteBtn:is(:hover,:active,:focus),
.confirmDeleteCancelBtn,
.confirmDeleteCancelBtn:is(:hover,:active,:focus) {
    height: 2rem;
    width: 6rem;
    border: none;
    border-radius: 2rem;
    background-color: #161d2d;
    color: #ddd;
}

.nameCatContainer {
    width: 25rem;
}

@media screen and (max-width: 768px) {
    .container {
        width: 100%;
    }
    .body {
        flex-direction: column;
    }
    .nameCatContainer {
        margin-top: 2rem;
        width: 22rem;
    }
}

@media screen and (max-width: 600px) {
    .container {
        padding: 1rem .75rem;
    }
}
