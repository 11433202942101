.container {
    width: 100%;
    height: fit-content;
    padding: 1.25rem 0;
    border-top: 1px solid #bbb;
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.inputGroup {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    position: relative;
}

.fileInput {
    position: absolute;
    z-index: -1;
    opacity: 0;
    width: .1px;
    height: .1px;
    overflow: hidden;
}


.label,
.label:is(:hover,:focus,:active) {
    cursor: pointer;
    height: 2rem;
    width: 8rem;
    margin-right: .5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    border-radius: 2rem;
    background-color: #f6f6f6;
    color: #161d2d;
    box-shadow: 2px 2px 6px #888;
}

.removeBtn,
.removeBtn:is(:hover,:focus,:active) {
    width: 6rem;
    height: 2rem;
    border: none;
    margin-left: .5rem;
    border-radius: 2rem;
    color: #161d2d;
    background-color: #f6f6f6;
    box-shadow: 2px 2px 6px #888;
}
.removeBtn:disabled {
    opacity: .6;
}

.chosen {
    width: 100%;
    height: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: .5rem;
}

.error {
    color: red;
    font-size: .8rem;
    margin-top: .5rem;
}