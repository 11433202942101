.wrapper {
    width: 100%;
    height: 100vh;
}
.container {
    width: 100%;
    height: 100%;
    padding: 10rem 5rem 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
}
.card {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 65rem;
    height: fit-content;
    background-color: #f6f6f6;
    box-shadow:  2px 2px 8px #888;
    border-radius: 1rem;
    z-index: 5;
    position: relative;
}

.column1,
.column2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    height: 100%;
    position: relative;
    padding: 0 .5rem;
}
.column1 {
    width: 15%;
    min-width: 8rem;
    padding: .75rem 0 4rem;
    text-align: center;
    border-right: 1px solid #bbb;
}
.column2 {
    width: 85%;
}


.title,
.contentTitle {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    height: 2rem;
    font-size: 1.1rem;
    background-color: transparent;
    color: #f6f6f6;
    border-radius: 2.5rem;
    position: absolute;
    bottom: 1.25rem;
    z-index: 5;
}
.contentTitle {
    top: -1.5rem;
}

.contentTitleInner {
    width: 60%;
    height: 100%;
    border: none;
    border-radius: 2.5rem;
    background-color: #161d2d;
    color: #f6f6f6;
    display: flex;
    justify-content: center;
    align-items: center;
}

.content {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 3rem;
    font-size: 1rem;
    margin: auto 0;
}

.tiers {
    width: calc((100% / 5) - 2rem);
    min-width: 5.5rem;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border: none;
    box-shadow: 2px 2px 5px #888;
    background-color: #161d2d;
    border-radius: 2.5rem;
    color: #f6f6f6;
    font-size: 1rem;
    transition: background-color 1s ease, color 1s ease, border .2s ease;
}
.tiers:hover {
    border: 4px solid #607aa4;
    transition: border .2s ease;
}
.tiers.current {
    background-color: #888;
    border: none;
    color: #f6f6f6;
    transition: background-color 1s ease, color 1s ease;
}

.titleCurrent {
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: space-around;
    align-items: center;
    font-size: .8rem;
}
.titleCurrentTiers {
    width: 25%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.middle {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
}
.middle0,
.middle1,
.middle2,
.middle3,
.middle4,
.middle5 {
    width: calc(100% / 5);
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    border-right: 1px solid #ddd;
    padding: .75rem 0 4rem;
    font-weight: bold;
}
.middle5 {
    border: none;
}
.middle0 div,
.middle1 div,
.middle2 div,
.middle3 div,
.middle4 div,
.middle5 div {
    height: 3rem;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
}

.middleMobile {
    display: none;
}

.stripeContainer {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: -1.5rem;
    right: 1.5rem;
}

.footer {
    position: absolute;
    width: 100%;
    height: 12rem;
    bottom: 0;
    background-size: 20rem !important;
    background-position: bottom;
    background-repeat: repeat-x;
    overflow-x: hidden;
    opacity: .08;
}

.collectionRedirect {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.collectionRedirectBtn,
.collectionRedirectBtn:is(:active,:hover,:focus) {
    display: flex;
    width: 12rem;
    height: 2.5rem;
    border: none;
    border-radius: 2.5rem;
    background-color: #161d2d;
    color: #ddd;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    margin-top: 0.5rem;
}

@media screen and (max-width: 992px) {
    .container {
        padding: 10rem 1rem 2rem;
    }
}
 
@media screen and (max-width: 768px) {
    .container {
        padding: 8rem 2rem 2rem;
    }
    .middleMobile {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
    }
    .middle {
        display: none;
    }
    .column1 {
        width: 25%;
    }
    .column2 {
        width: 75%;
    }
    .title {
        width: 100%;
        padding: 0 .75rem;
        justify-content: center;
    }
    .tiers {
        border-radius: 0;
        font-size: .8rem;
        min-width: 4.5rem;
        width: calc(100% / 5);
    }
    .tiers:first-of-type {
        border-radius: 2rem 0 0 2rem;
    }
    .tiers:last-of-type {
        border-radius: 0 2rem 2rem 0;
    }
    .middle1,
    .middle2,
    .middle3,
    .middle4 {
        border-right: none;
    }
    .contentTitleInner {
        width: 70%;
    }
    .titleCurrent {
        padding: 0 .75rem;
        font-size: .7rem;
    }
    .titleCurrentTiers {
        min-width: 4.5rem;
        width: calc(100% / 5);
    }
}
@media screen and (max-width: 600px) {
    .container {
        padding: 8rem .5rem 2rem;
    }
    .card {
        min-height: 23rem;
    }
    .title {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        bottom: 5rem
    }
    .tiers {
        border-radius: 2rem !important;
        margin: .6rem .5rem;
    }
    .titleCurrentTiers {
        margin: .6rem .5rem;
    }
    .column1 {
        padding: .5rem 0 7.25rem; 
    }
    .titleCurrent {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        bottom: 3rem;
        height: 2rem;
    }
}

@media screen and (max-width: 431px) {
    .column1 {
        padding: .5rem 0 10.5rem;
    }
    .title {
        bottom: 8.5rem;
    }
    .titleCurrent {
        bottom: 4.5rem;
    }
}