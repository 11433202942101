.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.inputGroup {
    width: 100%;
    height: fit-content;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;
}

.input {
    height: 3rem;
    width: 100%;
    border-radius: 3rem !important;
    border: none;
    padding: .5rem 0 0 1.5rem;
    background-color: #f6f6f6;
    box-shadow: 2px 2px 8px #888;
    color: #161d2d;
    outline: none;
    appearance: none;
    margin-bottom: 1.25rem;
}

.label {
    position: absolute;
    pointer-events: none;
    left: 1.5rem;
    top: .55rem;
    font-size: 1.25rem;
    transition: 0.2s ease all;
    color: #000;
    opacity: 0.8;
}

.input:focus + .label,
.input:-webkit-autofill + .label,
.input:not([value='']) + .label {
    top: .1rem;
    left: 1.5rem;
    font-size: 11px;
    text-transform: uppercase;
    opacity: 1;
}

.authButtons {
    margin-top: 0.5rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 0.5rem;
    align-items: center;
}

.btn,
.btn:is(:hover,:active,:focus) {
    width: 16rem;
    height: 2.5rem;
    border: none;
    border-radius: 2.5rem;
    color: #f6f6f6;
    background-color: #161d2d;
}

.btn:disabled {
    opacity: 0.7
}

.link,
.link:is(:hover,:active,:focus) {
    display: flex;
    text-decoration: none;
    width: 16rem;
    height: 2.5rem;
    justify-content: center;
    align-items: center;
    text-align: center;
    border: none;
    border-radius: 2.5rem;
    color: #f6f6f6;
    background-color: #161d2d;
    margin-bottom: 1rem;
}

.authenticatedContainer {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin: 1rem;
    justify-content: center;
    align-items: center;
}

.list {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.span {
    font-size: 0.8rem;
    margin-top: 0;
}

.loginBtn,
.loginBtn:is(:hover,:active,:focus) {
    background: none;
    border: none;
    color: blue;
    cursor: pointer;
    padding: 0;
    font-size: inherit;
    text-decoration: underline;
}