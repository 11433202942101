.inputGroup,
.inputGroupSelect {
    width: 100%;
    height: fit-content;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    margin-bottom: 1.25rem;
}

.inputGroupSelect:after {
    position: absolute;
    content: '\203A';
    top: .5rem;
    right: 1rem;
    font-size: 1.5rem;
    transform: rotate(90deg);
    pointer-events: none;
}

.select,
.select:is(:hover,:focus,:active) {
    height: 3rem;
    width: 100%;
    border-radius: 3rem !important;
    border: none;
    padding-left: 1.5rem;
    background-color: #f6f6f6;
    box-shadow: 2px 2px 8px #888;
    color: #161d2d;
    outline: none;
    -webkit-appearance: none;
}
.select:disabled,
.select:disabled div {
    color: #bbb !important;
}

.input {
    height: 3rem;
    width: 100%;
    border-radius: 3rem;
    border: none;
    padding-top: .5rem;
    padding-left: 1.5rem;
    background-color: #f6f6f6;
    box-shadow: 2px 2px 8px #888;
    color: #161d2d;
    outline: none;
    appearance: none;
}
.label {
    position: absolute;
    pointer-events: none;
    left: 1.5rem;
    top: .55rem;
    font-size: 1.25rem;
    transition: 0.2s ease all;
    color: #000;
    opacity: 0.8;
}

.input:focus + .label,
.input:-webkit-autofill + .label,
.input:not([value='']) + .label {
    top: 0rem;
    left: 1.5rem;
    font-size: 11px;
    text-transform: uppercase;
    opacity: 1;
}

.btn,
.btn:is(:hover,:focus,:active) {
    width: 2.5rem;
    height: 3rem;
    border: none;
    border-radius: 0 3rem 3rem 0;
    background-color: #f6f6f6;
    box-shadow: 2px 2px 8px #888;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #161d2d;
}