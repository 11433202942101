.wrapper {
    width: 100%;
    height: 100vh;
}

.container {
    width: 100%;
    max-width: 30rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 8rem 1rem 2rem;
    color: #161d2d;
    margin: 0 auto;
}