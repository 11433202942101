.imgWrap {
    width: fit-content;
    height: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
}
.image {
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 30rem;
    cursor: pointer;
}

.pdfWrap {
    width: 100%;
    height: 10rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.pdfBtn,
.pdfBtn:is(:hover,:focus,:active) {
    width: 10rem;
    height: 2.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #f6f6f6;
    background: #161d2d;
    text-decoration: none;
    cursor: pointer;
    border: none;
    border-radius: 2.5rem;
    padding: 0 1rem;
    font-size: 1.25rem;
    box-shadow: 2px 2px 5px #000;
}

.pdfBtn:disabled {
    opacity: .6;
    cursor: default;
}

@media screen and (max-width: 768px) {
    .carousel {
        width: 100%;
        padding: 0 .5rem 1.75rem;
    }
    .video {
        width: 100%;
        height: auto;
    }
}