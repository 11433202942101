.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}

:global(.tox) {
    width: 100%;
}

.previewBtn,
.previewBtn:is(:hover,:focus,:active) {
    border: none;
    box-shadow: 2px 2px 8px #888;
    border-radius: 2rem;
    width: 6rem;
    height: 2.5rem;
    text-shadow: none;
    color: #f6f6f6;
    background-color: #161d2d;
    outline: none !important;
    margin: 1rem 0 0;
}

.modal {
    z-index: 1400;
}

.modal :global(.modal-content) {
    border-radius: 1rem !important;
}
.modalHeader {
    padding: .5rem .75rem .5rem 1rem;
}

.bodyWrap {
    width: 100%;
    height: 40rem;
    background-repeat: no-repeat;
    background-size: 25rem 40rem;
    background-position: center;
    position: relative;
}

:global(.tox) {
    width: 100%;
}

.time {
    position: absolute;
    top: 3.6rem;
    left: 7.3rem;
    font-size: .8rem;
    font-weight: bold;
}
.barsBattery {
    position: absolute;
    top: 3.6rem;
    right: 7.3rem;
    font-size: .8rem;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
}

.bodyContainer {
    position: absolute;
    top: 4.9rem;
    left: 6.2rem;
    width: 16.6rem;
    height: 32rem;
    border-radius: 0 0 1.65rem 1.65rem;
    overflow: hidden;
    padding: .5rem;
}

@media screen and (max-width: 575px) {
    .bodyContainer {
        width: 100%;
        left: 0;
        top: 0;
    }
    .time {
        left: calc(50% - 7.3rem);
    }
    .barsBattery {
        right: calc(50% - 7.3rem);
    }
}