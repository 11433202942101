.headerContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100%;
}

.requiredLabel {
    position: absolute;
    margin: 0;
    top: -1.5rem;
    left: 5.5%;
}
.requiredDelete {
    position: absolute;
    margin: 0;
    top: -1.5rem;
    right: .6rem;
}

.column {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    touch-action: none;
}

.colDragIcon {
    cursor: grab;
    margin: 0 .25rem;
    width: 5%;
}

.archiveSelected {
    width: 15%;
    height: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    color: #000000;
    background-color: #f6f6f6;
    border: none;
    box-shadow: 2px 2px 6px #888;
    border-radius: 2rem;
    padding: 0rem 1rem;
    margin: 0 .25rem;
    outline: none;
    transition: outline .1s ease;
}
.archiveSelected:not(:disabled):hover {
    outline: 2px solid #161d2d;
    transition: outline .1s ease;
}
.dateOption {
    opacity: .7;
    cursor: default !important;
    pointer-events: none;
}

.colDetails {
    max-width: 12rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.colDetailContainer{
    width: 80%;
}

.colDetailContainerArchived {
    width: 72.5%;
}

.colDetailContainer,
.colDetailContainerArchived {
    width: 72.5%;
    height: 2rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    border: none;
    background-color: #f6f6f6;
    box-shadow: 2px 2px 6px #888;
    border-radius: 1rem;
    padding: 0 1rem;
    margin: .25rem;
    outline: none;
    transition: outline .1s ease;
}
.colDetailContainer:not(:disabled):hover {
    outline: 2px solid #161d2d;
    transition: outline .1s ease;
}
.colDetailContainerDisabled {
    opacity: .7;
}
.colDetailContainerDisabled:hover {
    outline: none !important;
    cursor: not-allowed;
}
.colDetailContainerArchived {
    cursor: default;
}

.colDetails p,
.archiveSelected p {
    margin: 0;
}

.droppableDiv {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    margin-bottom: .5rem;
}

.deleteColumnIcon {
    color: #ae0c1c;
    cursor: pointer;
    margin: 0 .25rem;
    font-size: 1.5rem;
    width: 7.5%;
}

.reqCheckbox {
    margin: 0 .25rem;
    width: 7.5%;
    accent-color: #161d2d;
}

@media screen and (max-width: 500px) {
    .archiveSelected {
        font-size: .8rem;
        padding: 0 .5rem
    } 
    .colDetails:first-of-type {
        width: 6rem;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        text-align: left;
    } 
    .colDetails {
        white-space: nowrap;
    }
    .requiredLabel,
    .requiredDelete {
        font-size: .8rem;
    }
    .requiredDelete {
        right: 0;
    }
    .reqCheckbox {
        margin: 0 .25rem 0 .5rem;
    }
}