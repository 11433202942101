.qrs,
.qrsReorder {
    width: 100%;
    height: fit-content;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    visibility: visible;
    opacity: 1;
    transition: visibility .5s ease, opacity .5s ease;
}
.qrs.hide,
.qrsReorder.hide {
    visibility: hidden;
    opacity: 0;
    transition: visibility .5s ease, opacity .5s ease;
}

.qrsSection {
    width: 100%;
    height: fit-content;
    max-height: 99999px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 1.5rem 1.25rem;
    margin: 0 0 2rem;
    transition: max-height .5s ease, margin .5s ease, opacity .5s ease;
}

.sectionHeader {
    width: 100%;
    height: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #161d2d;
    color: #f6f6f6;
    border: none;
    border-radius: 2rem;
    box-shadow: 2px 2px 5px #bbb;
    font-size: 1.35rem;
    margin: 0 0 1.25rem;
    padding: 0 1rem;
    cursor: pointer;
    position: relative;
}
.sectionHeader span {
    width: fit-content;
    max-width: 24rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    pointer-events: none;
}

.sectionHeader i {
    pointer-events: none;
    margin: .15rem 0 0;
    font-size: 1.1rem;
    transform: scale(-1, 1);
    transition: transform .3s ease, margin .3s ease;
}
.sectionHeader.close i {
    margin: 0 0 .15rem;
    transform: scale(1, -1);
    transition: transform .3s ease, margin .3s ease;
}

.resultsTitle {
    font-size: 1.25rem;
    margin-bottom: 1rem;
}
.resultsTitle span {
    font-weight: bold;
}

@media screen and (max-width: 400px) {
    .sectionHeader span {
        max-width: 12rem;
    }
}