.wrapper {
    width: 100%;
    height: 100vh;
}

.container {
    width: 100%;
    height: fit-content;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin: 0 auto;
    padding: 8rem 3rem 4rem;
}

.display {
    width: 100%;
    max-width: 70rem;
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    border: none;
    border-radius: 1rem;
    box-shadow: 2px 2px 8px #888;
    color: #161d2d;
    background-color: #f6f6f6;
    outline: none;
    -webkit-appearance: none;
    padding: 1rem;
}

.deleteText {
    color: red;
    font-size: 0.8rem;
    position: absolute;
    top: .35rem;
    left: 2rem;
}

.header {
    width: calc(100% + 2rem);
    background-color: #607aa4;
    color: #f6f6f6;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: -1rem;
    margin-bottom: .5rem;
    border-radius: 1rem 1rem 0 0;
    border-bottom: 2px solid #ccc;
}

.trackerCanvas {
    width: 500px !important;
    height: 100px !important;
    border: 1px solid #ccc;
    box-shadow: 2px 2px 8px #888;
    border-radius: .5rem;
    outline: none;
    appearance: none;
}

.navBtnCont {
    position: absolute;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: fit-content;
    top: -1.75rem;
    left: 1rem;
}

.editBtn,
.editBtn:is(:hover,:focus,:active) {
    width: fit-content;
    height: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    padding: 0 1rem;
    border: none;
    border-radius: 2rem;
    box-shadow: 1px 1px 5px #222;
    color: #f6f6f6;
    background: #161d2d;
    font-size: 1.25rem;
    top: -1.75rem;
    right: 1rem;
}

.editBtn:disabled {
    opacity: 0.6;
}

.cancelDeleteBtn,
.cancelDeleteBtn:is(:hover,:focus,:active) {
    background-color: #f6f6f6 !important;
    color: #161d2d !important;
}

.backBtn,
.backBtn:is(:hover,:focus,:active),
.deleteBtn,
.deleteBtn:is(:hover,:focus,:active),
.cancelDeleteBtn,
.cancelDeleteBtn:is(:hover,:focus,:active) {
    width: fit-content;
    height: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 .25rem;
    padding: 0 1rem;
    border: none;
    border-radius: 2rem;
    box-shadow: 1px 1px 5px #222;
    color: #f6f6f6;
    background: #161d2d;
    font-size: 1.25rem;
};

.textareaDescription {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
    min-height: 5rem;
    border: 1px solid #ccc;
    border-radius: 0.5rem;
    box-shadow: 2px 2px 8px #888;
    padding: .5rem .75rem;
    text-decoration: none;
    outline: none;
    margin-top: .75rem;
}

.description {
    width: 100%;
    height: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

@media screen and (max-width: 600px) {
    .container {
        padding: 8rem .5rem 4rem;
    }
}