.modal :global(.modal-dialog) {
    margin-top: 5rem !important;
}
.modal :global(.modal-content) {
    border-radius: 1rem;
    overflow: hidden;
}


.header {
    display: flex !important;
    justify-content: center;
    background-color: #607aa4;
    color: #f6f6f6;
}
.header :global(button) {
    background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23f6f6f6'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat;
    opacity: 1;
}

.body {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    overflow: hidden;
}
.top {
    width: 100%;
    height: 24rem;
    display: flex;
    justify-content: center;
    align-items: center;
}
.topLeft {
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-right: .5rem;
}
.topRight {
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-left: .5rem;
}
.codeWrap {
    width: 100%;
    height: fit-content;
    background-color: #f6f6f6;
}

.bottom {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.inputGroup {
    width: 100%;
    height: fit-content;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    z-index: 10;
}
.inputGroupSelect:after {
    position: absolute;
    content: '\203A';
    top: .2rem;
    right: 1rem;
    font-size: 1.5rem;
    transform: rotate(90deg);
    pointer-events: none;
}
.input,
.input:is(:hover,:focus,:active) {
    width: 100%;
    height: 2.5rem;
    border: none;
    border-radius: 2.5rem !important;
    box-shadow: 2px 2px 6px #888;
    padding: .3rem 1rem 0;
    outline: none;
    background-color: #f6f6f6;
    color: #161d2d;
}
.label {
    position: absolute;
    pointer-events: none;
    left: 1rem;
    top: .45rem;
    font-size: 1.15rem;
    color: #000;
    opacity: 0.8;
    transition: 0.2s ease all;
}

.input:focus + .label,
.input:-webkit-autofill + .label,
.input:not([value='']) + .label {
    top: 0rem;
    left: 1rem;
    font-size: 9px;
    text-transform: uppercase;
    opacity: 1;
}

.words {
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 5;
}

.btn,
.btn:is(:hover,:focus,:active) {
    width: 8rem;
    height: 2.5rem;
    border: none;
    border-radius: 2.5rem;
    background-color: #161d2d;
    color: #f6f6f6;
    z-index: 5;
    margin-top: .5rem;
}

.background {
    position: absolute;
    left: 0rem;
    bottom: -3rem;
}
.background img {
    height: 18rem;
    width: 32rem;
    opacity: .05;
}


/* color picker styling */
.swatch {
    height: 2rem;
    padding: 0 .5rem;
    width: 5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #fff;
    border: 1px solid #bbb;
    border-radius: 2rem;
    cursor: pointer;
}
.popover {
    position: absolute;
    bottom: 2.25rem;
    z-index: 4;
}
.cover {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}
.color {
    width: 100%;
    height: 1rem;
    border: 1px solid #000;
    border-radius: 1rem;
}
/* end color picker styling */




@media screen and (max-width: 480px) {
    .swatch {
        min-width: 4rem;
        width: 4rem;
    }
    .inputGroup label {
        white-space: nowrap;
        font-size: .9rem;
    }
}