.card {
    flex-direction: column;
    height: 21.25rem;
    width: 16.5rem;
    position: relative;
}
.front,
.back {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    background: linear-gradient(to bottom, #607aa4 20%, #f6f6f6 20%);
    box-shadow: 2px 2px 8px #888;
    border-radius: 1rem;
    padding: .75rem .5rem 1rem;
}
.front {
    transform: rotateY(0deg);
    transition: transform .5s ease;
}
.back {
    transform: rotateY(180deg);
    transition: transform .5s ease;
}
.front.flip {
    transform: rotateY(-180deg);
    transition: transform .5s ease;
}
.back.flip {
    transform: rotateY(0deg);
    transition: transform .5s ease;
}

.flipBtn {
    width: fit-content;
    height: 2rem;
    border: none;
    border-radius: 50%;
    color: #161d2d;
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.5rem;
    cursor: pointer;
    margin-left: .35rem;
    padding: 0;
}

.codeWrap {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    padding: .5rem 0 1rem;
    height: 11.25rem
}
.codeWrapInner {
    width: 100%;
    height: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: .75rem;
}
/* .code {
    width: 8rem;
    height: 8rem;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
.code svg {
    transform: scale(.5)
} */
.scanCount {
    height: 2rem;
    width: fit-content;
    margin-right: .35rem;
    font-size: 1.25rem;
    display: flex;
    justify-content: center;
    align-items: center;
}
.scanCount i {
    font-size: 1.5rem;
}

.divider {
    height: 1rem;
    width: calc(100% + 1rem);
    margin-left: -.5rem;
    border-right: none;
    border-top: 2px solid #ccc;
}

.iconType {
    position: absolute;
    top: .25rem;
    left: .5rem;
    font-size: 1rem;
    color: #f6f6f6;
}

.content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 3.5rem;
    width: 100%;
    text-align: center;
}
.contentInner {
    display: inline;
    line-height: 1rem;
    white-space: nowrap;
    max-width: 10rem;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 1.25rem;
    margin-bottom: .25rem;
    color: #f6f6f6;
}
.contentInner span {
    font-size: 1.35rem;
    color: #f6f6f6;
}

.category {
    display: inline;
    white-space: nowrap; 
    max-width: 100%; 
    overflow: hidden; 
    text-overflow: ellipsis;
}

.btnContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
    height: 3rem;
    padding: 0 1rem;
}

.delete,
.delete:is(:hover,:focus,:active) {
    position: absolute;
    background-color: #f6f6f6;
    height: 1.75rem;
    width: 1.75rem;
    border-radius: 1.75rem;
    border: none;
    box-shadow: 2px 2px 5px #bbb;
    top: -1rem;
    right: -1rem;
    padding: .1rem 0 0 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
    color: #000;
    transition: width .25s ease;
}
.delete.confirm {
    cursor: default;
    width: 12rem;
    transition: width .25s ease;
}

.deleteContent {
    display: inline;
    position: absolute;
    width: 100%;
    opacity: 0;
    top: 0.1rem;
    left: 0;
    transition: opacity .25s ease;
}
.deleteContent.confirm {
    opacity: 1;
    transition: opacity .25s ease;
}

.extraSpace {
    position: absolute;
    width: 4rem;
    height: 4rem;
    border-radius: 50%;
    border: none;
    background-color: transparent;
    top: -1.5rem;
    right: -2.5rem;
}

.deleteCheck,
.cancelX {
    width: 1.5rem;
    height: 1.5rem;
    font-weight: bold;
    cursor: pointer;
    display: inline;
}
.deleteCheck:hover {
    color: green;
}
.cancelX:hover {
    color: red;
}

.edit,
.edit:is(:hover,:focus,:active),
.download,
.download:is(:hover,:focus,:active) {
    background: #f6f6f6 !important;
    /* box-shadow:  2px 1px 8px #777 !important; */
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
    border: none;
    height: 2.5rem;
    width: 5rem;
    border-radius: 2rem;
    color: #161d2d !important;
    font-size: .9rem;
    outline: none !important;
}

.dropdownMenu {
    top: -7.25rem !important;
    left: -3rem !important;
    transform: none !important;
    box-shadow: 1px 1px 5px #bbb !important;
    outline: none;
}
.btnContainer :global(.show>.btn-primary.dropdown-toggle:focus),
.btnContainer :global(.show>.btn-primary.dropdown-toggle:active) {
    box-shadow: 1px 1px 5px #bbb;
}
.btnContainer :global(.dropdown-item:is(:hover,:focus,:active)) {
    background-color: #ddd;
}

.childBtn,
.childBtn:is(:hover,:focus,:active) {
    height: 2rem;
    width: fit-content;
    margin: 0 .35rem;
    font-size: 1.25rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    background: transparent;
}
.childBtn i {
    font-size: 1.25rem;
}

.linkedModal :global(.modal-dialog) {
    margin-top: 5rem;
    max-width: 27rem;
}
.linkedModal :global(.modal-content) {
    border-radius: .75rem;
    background: #f6f6f6;
}
.linkedModal :global(.modal-header) {
    padding: .5rem 1rem;
    justify-content: center !important;
    position: relative;
}
.linkedModal :global(.modal-header) span {
    font-size: 1.25rem;
}
.linkedModal :global(.btn-close) {
    position: absolute;
    top: 1rem;
    right: 1rem;
}
.linkedModal :global(.modal-body) {
    padding: 1rem 0;
}

/* swiper js styling */
.qrSwiper {
    width: 100%;
    height: 16rem;
    padding: 1rem;
}
.qrSwiperSlide {
    width: 10.5rem !important;
    height: 23rem;
}
.qrSwiperSlide {
    display: flex;
    justify-content: center;
    align-items: flex-start;
}
.qrSwiper :global(.swiper-pagination) {
    bottom: -.25rem;
}
.qrSwiper :global(.swiper-pagination-bullet-active) {
    background: #161d2d;
}
/* end swiper styling */

@media screen and (max-width: 600px) {
    .card {
        max-width: 100%;
    }
}