.section {
    width: 35rem;
    height: fit-content;
    padding: 1rem;
    border: none;
    border-radius: .75rem;
    box-shadow: 1px 1px 8px #616161;
    background-color: #f6f6f6;
}

.btn,
.btn:is(:hover,:focus,:active) {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 4rem;
    height: 1.5rem;
    border: none;
    border-radius: 1.5rem;
    margin: 0 0.5rem .25rem;
    box-shadow: 1px 1px 5px #616161;
    outline: none;
    appearance: none;
    background-color: #eee;
    color: #161d2d;
}

.signatureLabelContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: fit-content;
}

.trackerCanvas {
    width: 350px !important;
    height: 100px !important;
    border: 1px solid #ccc;
    box-shadow: 2px 2px 8px #616161;
    border-radius: 1rem;
    outline: none;
    appearance: none;
}

.inputGroup,
.inputGroupSelect {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    position: relative;
    margin-bottom: .5rem;
}
.inputGroupSelect:after {
    position: absolute;
    content: '\203A';
    bottom: 0.1rem;
    right: 1rem;
    font-size: 1.5rem;
    transform: rotate(90deg);
    pointer-events: none;
}

.textarea {
    height: 4rem;
    max-height: 6rem;
    overflow: auto;
    width: 100%;
    border-radius: 1rem !important;
    border: none;
    background-color: #f6f6f6;
    color: #161d2d;
    box-shadow: 1px 1px 8px #616161;
    outline: none;
    appearance: none;
    padding: .25rem 1.25rem 0;
}

.label i {
    font-size: .7rem;
    color: red;
    margin-left: .1rem;
}

.dropdown {
    height: 2.5rem;
    width: 100%;
    border-radius: 2.5rem !important;
    border: none;
    padding: 0 1.5rem;
    background-color: #f6f6f6;
    box-shadow: 1px 1px 8px #616161;
    color: #000;
    opacity: 0.8;
    outline: none;
    appearance: none;
    font-size: 1.15rem;
    accent-color: #161d2d;
}

.checkGroup {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    height: fit-content;
    width:calc(100% - 2.5rem);
    margin-left: 2.5rem;
}
.checkbox {
    accent-color: #161d2d;
    margin-right: .25rem;
}

.checkboxOption {
    font-size: 1.15rem;
    color: #000;
    opacity: 0.8;
}

.input {
    width: 100%;
    height: 2.5rem;
    border: none;
    border-radius: 2.5rem;
    box-shadow: 1px 1px 8px #616161;
    background-color: #f6f6f6;
    color: #161d2d;
    padding: 0 1.25rem;
    outline: none;
    appearance: none;
}

.label {
    width: fit-content;
    height: fit-content;
    position: relative;
    pointer-events: none;
    font-size: 1.25rem;
    color: #161d2d;
    margin-left: 1.25rem;
    margin-bottom: .5rem;
}

@media (max-width: 768px) {
    .section {
        width: 100%;
    }
    .inputGroup {
        width: 100%;
    }
    .textarea {
        width: 100%;
        margin-left: 0;
    }
    .dropdown {
        width: 100%;
        margin-left: 0;
    }
    .input {
        width: 100%;
        margin-left: 0;
    }
    .checkGroup {
        width: 100%;
    }
}

@media screen and (max-width: 450px) {
    .trackerCanvas {
        transform: scale(.9);
        margin-left: -1.2rem;
    }
}
@media screen and (max-width: 400px) {
    .trackerCanvas {
        transform: scale(.85);
        margin-left: -1.7rem;
    }
}