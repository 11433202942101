.linkContainer,
.pdfContainer,
.menuContainer {
    width: 100%;
    height: fit-content;
    padding: .5rem 0;
}

.inputGroup,
.inputGroupSelect,
.inputGroupRedirect {
    width: 100%;
    height: fit-content;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;
}
.inputGroupSelect:after {
    position: absolute;
    content: '\203A';
    top: .25rem;
    right: .5rem;
    transform: rotate(90deg);
    pointer-events: none;
}
.inputGroupRedirect {
    display: none;
}
.select {
    width: 100%;
    height: 2rem;
    border: none;
    border-radius: 2rem;
    background-color: #f6f6f6;
    box-shadow: 2px 2px 5px #888;
    padding: 0 .75rem;
    appearance: none;
    outline: none;
    color: #161d2d;
}


.redirectLabel {
    display: none;
    margin-left: .5rem;
}

.redirectInput {
    width: calc(100% - 4.5rem);
    height: 2rem;
    border: none;
    border-radius: 2rem 0 0 2rem;
    outline: none;
    appearance: none;
    padding: 0 .5rem;
    box-shadow: 2px 2px 5px #888;
}
.addBtn,
.addBtn:is(:hover,:focus,:active) {
    width: 4.5rem;
    height: 2rem;
    border: none;
    background-color: #161d2d;
    color: #f6f6f6;
    border-radius: 0 2rem 2rem 0;
    box-shadow: 2px 2px 5px #888;
}
.addBtn:disabled {
    opacity: .8;
}

.addPdfWrap,
.addMenuWrap {
    width: 100%;
    height: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
}
.addPdfBtn,
.addPdfBtn:is(:hover,:focus,:active),
.addMenuBtn,
.addMenuBtn:is(:hover,:focus,:active) {
    width: 6rem;
    height: 2rem;
    margin-top: .5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    border-radius: 2rem;
    background-color: #161d2d;
    color: #f6f6f6;
    box-shadow: 2px 2px 8px #888;
}
.addPdfBtn:disabled,
.addMenuBtn:disabled {
    opacity: .7;
    color: #ddd;
}

.pdfInput,
.pdfInput:is(:hover,:active) {
    width: 100%;
    height: 2.5rem;
    border: none;
    border-radius: 2.5rem;
    box-shadow: 2px 2px 5px #888;
    appearance: none;
    outline: none;
    padding: .1rem 1rem 0;
}
.pdfLabel {
    position: absolute;
    top: .5rem;
    left: 1rem;
    text-transform: none;
    transition: all .2s ease;
}
.pdfInput:focus + .pdfLabel,
.pdfInput:not([value='']) + .pdfLabel {
    top: 0;
    font-size: .6rem;
    text-transform: uppercase;
    transition: all .2s ease;
}

.fileInput {
    position: absolute;
    z-index: -1;
    opacity: 0;
    width: .1px;
    height: .1px;
    overflow: hidden;
}
.fileLabel,
.fileLabel:is(:hover,:focus,:active) {
    cursor: pointer;
    height: 2rem;
    width: 8rem;
    margin-right: .5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    border-radius: 2rem;
    background-color: #161d2d;
    color: #f6f6f6;
    margin-top: .5rem;
    box-shadow: 2px 2px 6px #888;
}

.input,
.input:is(:hover,:focus,:active) {
    height: 2.5rem;
    width: 100%;
    border-radius: 2.5rem;
    border: none;
    padding-top: .5rem;
    padding-left: 1.25rem;
    background-color: #f6f6f6;
    box-shadow: 2px 2px 8px #888;
    color: #161d2d;
    outline: none;
    appearance: none;
    -webkit-appearance: none;
}

.label {
    position: absolute;
    pointer-events: none;
    left: 1.25rem;
    top: .4rem;
    font-size: 1.25rem;
    transition: 0.2s ease all;
    color: #000;
    opacity: 0.8;
}

.input:focus + .label,
.input:-webkit-autofill + .label,
.input:not([value='']) + .label {
    top: 0rem;
    left: 1.25rem;
    font-size: 10px;
    text-transform: uppercase;
    opacity: 1;
}

.menuBtnWrap {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: .5rem;
}
.menuBtnContainer {
    width: 100%;
    height: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
}
.menuBtn {
    width: 7rem;
    height: 2rem;
    border: none;
    border-radius: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #161d2d;
    color: #f6f6f6;
    margin: 0 .5rem;
}