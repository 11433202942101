swiper-container {
    width: 100%;
    height: 100%;
}

swiper-slide {
    width: 100% !important;
    height: 100% !important;
    padding: 1rem 3rem 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

@media screen and (max-width: 768px) {
    swiper-slide {
        padding: 1rem 1rem 2rem;
    }
}