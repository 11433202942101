.addBtn,
.addBtn:is(:focus,:active) {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 2.5rem;
    width: 2.5rem;
    color: #000;
    padding: 0;
    background-color: #f6f6f6;
    border-radius: 2.5rem;
    z-index: 2;
    border: none;
    top: -1.25rem;
    left: 3rem;
    box-shadow: 2px 2px 5px #bbb;
    transition: width .25s ease;
}
.addBtn.stretch,
.addBtn.stretch:is(:focus,:active) {
    color: #000;
    width: 5rem;
    transition: width .25s ease;
}
.addBtn:disabled{
    background-color: #e9eaef;
    color: #bbb;
}

.addContent {
    opacity: 0;
    transition: opacity .25s ease;
    color: #000;
}
.addContent.stretch {
    opacity: 1;
    transition: opacity .25s ease;
}

@media screen and (max-width: 768px) {
    .addBtn,
    .addBtn:is(:focus,:active) {
        height: 2rem;
        width: 2rem;
        top: -.5rem;
        left: 2.25rem;
        font-size: .9rem;
    }
}

@media screen and (max-width: 500px) {
    .addBtn,
    .addBtn:is(:focus,:active) {
        top: -1.25rem;
        left: 1.75rem;
    }
}