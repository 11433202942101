.inputGroup {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    margin-bottom: 1rem;
}

.input {
    height: 3rem;
    width: calc(100% - 2.5rem);
    border-radius: 3rem 0 0 3rem;
    border: none;
    padding-top: .5rem;
    padding-left: 1.5rem;
    background-color: #f6f6f6;
    box-shadow: 2px 2px 8px #888;
    color: #161d2d;
    outline: none;
    appearance: none;
}
.label {
    position: absolute;
    pointer-events: none;
    left: 1.5rem;
    top: .55rem;
    font-size: 1.25rem;
    transition: 0.2s ease all;
    color: #000;
    opacity: 0.8;
}

.input:focus + .label,
.input:-webkit-autofill + .label,
.input:not([value='']) + .label {
    top: 0rem;
    left: 1.5rem;
    font-size: 11px;
    text-transform: uppercase;
    opacity: 1;
}

.btn,
.btn:is(:hover,:focus,:active) {
    width: 2.5rem;
    height: 3rem;
    border: none;
    border-radius: 0 3rem 3rem 0;
    background-color: #f6f6f6;
    box-shadow: 2px 2px 8px #888;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #161d2d;
}

.tags {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    padding: .25rem 1rem 0;
}
.tag {
    width: fit-content;
    height: 1.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 .5rem 0 .65rem;
    margin: .15rem;
    border: none;
    border-radius: 1.5rem; 
    background-color: #f6f6f6;
    box-shadow: 2px 2px 5px #888;
}
.tag i {
    cursor: pointer;
}